import { createContext, useContext, useEffect, useState } from "react";
import Loading from "../components/Loading/Loading";
import { api_url } from "../utils/data";
import axios from "axios";
import { Navigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [authenticating, setAuthenticating] = useState(true);
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null)
    const [packages, setPackages] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [pharmacyDetails, setPharmacyDetails] = useState([])
    const [pharmacySetup, setPharmacySetup] = useState(null)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    const getUser = async () => {
        if(localStorage.getItem("auth")){
            
            const auth = JSON.parse(localStorage.getItem("auth"));
            setAuthToken(auth['access']);
            const config = {
                headers: {
                  "Content-Type":'application/json',
                  Authorization:"Bearer " + auth['access']
                }
              } 
              axios.get(`${api_url}/accounts/me`,config).then((response)=>{
                setUser(response.data)
                if(response?.data?.is_superuser){
                    getPackages();
                }else if(response?.data?.is_pharmacy_admin || response?.data?.is_employee){
                    getPharmacyDetails(auth['access'])
                    getPharmacySetup(auth['access'])
                    getSubscriptionDetails(auth['access'])
                }
                setAuthenticating(false);
              }).catch((error)=>{
                setUser(null);
                setAuthenticating(false);
                localStorage.removeItem('auth');
              });
        }else{
            setUser(null);
            setAuthenticating(false);
        }
    }

    const getPackages = async () => {
        const config = {
            headers: {
              "Content-Type":'application/json',
            }
          } 
        await axios.get(`${api_url}/subscription/packages/`, config).then((response)=>{
            setPackages(response.data)
        }).catch((error)=>{
            
        })
    }

    const getCategories = async () => {
        const config = {
            headers: {
              "Content-Type":'application/json',
            }
          } 
        await axios.get(`${api_url}/inventory/medicine-categories/`, config).then((response)=>{
            setCategoryList(response.data)
        }).catch((error)=>{
            
        })
    }

    const getPharmacyDetails = async (access_token) => {
        const config = {
            headers: {
              "Content-Type":'application/json',
              Authorization: `Bearer ${access_token}`,
            }
          } 
        await axios.get(`${api_url}/accounts/pharmacy-details/`, config).then((response)=>{
            setPharmacyDetails(response.data)
        }).catch((error)=>{
            
        })
    }


    const getPharmacySetup = async (access_token) => {
        const config = {
            headers: {
              "Content-Type":'application/json',
              Authorization: `Bearer ${access_token}`,
            }
          } 
        await axios.get(`${api_url}/accounts/pharmacy-setup/`, config).then((response)=>{
            
            setPharmacySetup(response.data)
        }).catch((error)=>{
            
        })
    }

    const getSubscriptionDetails = async (access_token) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      };
  
      await axios
        .get(`${api_url}/subscription/pharmacy-subscription/`, config)
        .then((response) => {
          setSubscriptionDetails(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(()=>{
        getUser();
        getCategories();
        getPackages();
    },[])

    const contextData = {
        authenticating,
        user,
        setUser,
        getUser,
        packages,
        authToken,
        categoryList,
        pharmacyDetails,
        pharmacySetup,
        subscriptionDetails
    }

    if(authenticating){
        return (
            <Loading />
        )
    }else{
        return (
            <AuthContext.Provider value={contextData}>
                {children}
            </AuthContext.Provider>
        )
    }

}

export const useAuthenticate = () => useContext(AuthContext);