import './App.css';
import './Styles/style.css'
import './Styles/styles.css'
import './Styles/theme.css'
import './Styles/toastr.css'
import './Styles/vendor.css'
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Login from './pages/Login/Login';
import Layout from './components/Layout'
import ProtectedRoutes from './utils/ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from './context/AuthContext';



function App() {
  return (
    <BrowserRouter> 
    <AuthProvider>
      <Routes>
        <Route path='' element={<Login />} />
        <Route path='/*' element={<ProtectedRoutes component={Layout} />} />
      </Routes>
    </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={6608}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
    </BrowserRouter>
  );
}

export default App;
