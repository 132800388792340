import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { useAuthenticate } from '../../context/AuthContext'
import { RotatingLines } from 'react-loader-spinner'
import { MdDeleteOutline, MdOutlineRemoveRedEye } from 'react-icons/md'
import NoData from '../../components/NoData'
import axios from 'axios'
import { api_url } from '../../utils/data'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const SupplierPurchases = () => {
  const {authToken,subscriptionDetails} = useAuthenticate()
  const [supplierPurchases, setSupplierPurchases] = useState([])
  const [supplierPurchasesList, setSupplierPurchasesList] = useState([])
  const [loadingPurchases, setLoadingPurchases] = useState(true)
  const [deletingSupplierPurchase, setDeletingSupplierPurchase] = useState(false)

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = supplierPurchases.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(supplierPurchases.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % supplierPurchases.length;
    setItemOffset(newOffset);
  };

  const getPurchasesList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios
        .get(`${api_url}/supplier-management/purchase/`, config)
        .then((response) => {
          setSupplierPurchases(response.data);
          setSupplierPurchasesList(response.data);
          setLoadingPurchases(false);
        });
    } catch (error) {
      setLoadingPurchases(false);
      toast.error("An error occured please try again");
    }
  };

  const filterPurchasesList = (search) => {
    if (search === "") {
      setSupplierPurchases(supplierPurchasesList);
    } else {
      const filteredPurchasesList = supplierPurchasesList.filter((purchase) => {
        return purchase?.supplier?.toLowerCase().includes(search.toLowerCase());
      });
      setSupplierPurchases(filteredPurchasesList);
    }
  };

  const deleteSupplierPurchase = (purchase_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingSupplierPurchase(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${api_url}/supplier-management/purchase/${purchase_id}/`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Purchase has been deleted.",
                icon: "success",
              }).then(() => {
                getPurchasesList();
                setDeletingSupplierPurchase(false)
              });
            });
        } catch (error) {
          setDeletingSupplierPurchase(false)
          toast.error("Something went wrong.");
        }
      }
    });
  };

  useEffect(()=>{
    if(subscriptionDetails?.success){
      if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
        window.location.href = "/upgrade-subscription"
      }else{
        getPurchasesList();
      }
    }else{
      window.location.href="/no-subscription"
    }
    

  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--20"
                alt=""
              />
            </span>
            <span>
              Supplier Purchases List{" "}
              {/* <span className="badge badge-soft-primary ml-2 badge-pill">
                19
              </span> */}
            </span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card--header">
              <form>
                <div className="input-group">
                  <input
                    id="datatableSearch_"
                    type="search"
                    name="search"
                    className="form-control"
                    placeholder="Search by Supplier Name "
                    aria-label="Search"
                    defaultValue=""
                    required=""
                    onChange={(e) => filterPurchasesList(e.target.value)}
                  />
                </div>
              </form>
              <div>
                <Link to="/supplier-management/purchases/new" className="btn btn--primary py-2">
                  <span className="text">Add New Purchase</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            {loadingPurchases ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  strokeColor="blue"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <table className="table table-borderless table-hover table-align-middle m-0 text-14px">
                <thead className="thead-light">
                  <tr className="word-nobreak">
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Supplier</th>
                    <th className='text-center'>No. of items</th>
                    <th className="text-center">Gross Total</th>
                    <th className="text-center">Amount Paid</th>
                    <th className="text-center">Outstanding balance</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((purchase, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="table-column-pl-0">    
                        {purchase?.supplier}
                            
                        
                      </td>
                      <td>
                        <div className='text-center'>
                          {purchase?.number_of_items}    
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          <span>{purchase?.gross_total}</span>
                        </div>
                      </td>

                      <td>
                        <div className="text-center">
                          <span>{purchase?.amount_paid}</span>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">{purchase?.outstanding_balance}</div>
                      </td>

                      <td>
                        {
                          deletingSupplierPurchase ? (
                            <div className="d-flex align-items-center justify-content-center col-sm-12">
                                <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  color="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                          ):(

                            <div className="btn--container justify-content-center">
                              <Link
                                className="action-btn"
                                to={`/supplier-management/purchases/${purchase.id}/details`}
                              >
                                <MdOutlineRemoveRedEye />
                              </Link>
                              <a
                                className="action-btn btn--danger btn-outline-danger"
                                href="javascript:"
                                onClick={() => deleteSupplierPurchase(purchase.id)}
                              >
                                <MdDeleteOutline />
                              </a>
                            </div>
                          )
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="card-footer">
            <nav>
              <ReactPaginate
                breakLabel="..."
                nextLabel="›"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="‹"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="page-item active"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
              />
            </nav>
          </div>
          {supplierPurchases.length <= 0 && !loadingPurchases && <NoData />}
        </div>
      </div>
    </main>
  )
}

export default SupplierPurchases