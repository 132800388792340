import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import LogoSmall from "../../assets/rxsmall.jpeg"
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";

const MedicineDetails = () => {
  const { categoryList, authToken,subscriptionDetails } = useAuthenticate();
  const { medicine_id } = useParams();
  const [medicineDetails, setMedicineDetails] = useState(null);
  const [updatingMedicine, setUpdatingMedicine] = useState(false);
  const [gettingMedicine, setGettingMedicine] = useState(true)
  const [updateData, setUpdateData] = useState({});

  const changeFormData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const getMedicineDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios.get(`${api_url}/inventory/medicines/${medicine_id}/`,config).then((respone)=>{
      setMedicineDetails(respone.data);
      setGettingMedicine(false);
    }).catch(error=>{
      console.error(error);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    })
  };

  const calculateFinalPrice = (manufacturer_price, markup_rate) => {
    if (manufacturer_price !== "") {
      
      let tax_amount = parseFloat(manufacturer_price) * (parseFloat(markup_rate) / 100);
      
      let final_price = parseFloat(parseFloat(manufacturer_price) + tax_amount).toFixed(2);
      setUpdateData({
        ...updateData,
        ["manufacturer_price"]: manufacturer_price,
        ["markup_rate"]: markup_rate,
        ["final_price"]: final_price,
      });
    }
  };

  const updateMedicine = async (e) => {
    e.preventDefault();
    setUpdatingMedicine(true)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = new FormData();
    Object.keys(updateData).forEach((key) => {
      formData.append(key, updateData[key]);
    });

    try {
      await axios
        .put(
          `${api_url}/inventory/medicines/${medicine_id}/`,
          formData,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Updated!",
              text: "Medicine has been updated.",
              icon: "success",
            }).then(() => {
              setUpdatingMedicine(false);
              window.location.href = "/medicine/list";
            });
          }
        });
    } catch (error) {
      
      console.log(error);
      setUpdatingMedicine(false);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getMedicineDetails();
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/add-product.png"
                className="w--24"
                alt=""
              />
            </span>
            <span> Update Medicine </span>
          </h1>
        </div>
        {
          gettingMedicine ? (
            <Loading />
          ):(
            <form className="row g-2" onSubmit={(e) => updateMedicine(e)}>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body pt-2">
                    <ul className="nav nav-tabs mb-4">
                      <li className="nav-item">
                        <a
                          className="nav-link lang_link active"
                          href="#"
                          id="en-link"
                        >
                          Medicine Details
                        </a>
                      </li>
                    </ul>
                    <div className="lang_form" id="en-form">
                      <div className="form-group">
                        <label className="input-label" htmlFor="en_name">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="en_name"
                          className="form-control"
                          required
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.name
                              ? updateData?.name
                              : medicineDetails?.name
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label className="input-label" htmlFor="en_name">
                          Generic Name
                        </label>
                        <input
                          type="text"
                          name="generic_name"
                          id="en_name"
                          className="form-control"
                          placeholder="New Product"
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.generic_name
                              ? updateData?.generic_name
                              : medicineDetails?.generic_name
                          }
                        />
                      </div>

                      <div className="form-group mb-0">
                        <label className="input-label" htmlFor="en_description">
                          Short Description
                        </label>
                        <textarea
                          name="description"
                          className="form-control h--172px"
                          id="en_hiddenArea"
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.description
                              ? updateData?.description
                              : medicineDetails?.description
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* CATEGORY CARD */}
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      <span className="card-header-icon">
                        <i className="tio-user" />
                      </span>
                      <span> Category </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="input-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Category<span className="input-label-secondary">*</span>
                          </label>
                          <select
                            name="category"
                            className="form-control js-select2-custom"
                            onChange={(e) => changeFormData(e)}
                          >
                            <option value="">---Select---</option>
                            {categoryList.map((category, index) => (
                              <option
                                value={category.id}
                                selected={medicineDetails?.category === category.id}
                                key={index}
                              >
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* MEDICINE IMAGE CARD */}
                <div class="card mt-5">
                  <div class="card-body">
                    <h5 class="mb-3">
                      Medicine Image
                    </h5>
                    <div class="product--coba">
                      <div class="row g-2" id="coba">
                        <img
                          className="img-150 border rounded p-3"
                          src={
                            medicineDetails?.medicine_image
                              ? `"https://api.stewardspharmacy.com/" ${medicineDetails?.medicine_image}`
                              : LogoSmall
                          }
                          alt=""
                        />
                      </div>
                      <div className="mt-4">
                        <input
                          type="file"
                          name="medicine_image"
                          accept=".jpg, .png, .jpeg, .webp"
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      <span className="card-header-icon">
                        <i className="tio-dollar" />
                      </span>
                      <span> Price information </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="p-2">
                      <div className="row g-3">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlSelect1"
                            >
                              Medicine Type
                              <span className="input-label-secondary">*</span>
                            </label>
                            <select
                              name="medicine_type"
                              className="form-control js-select2-custom"
                              onChange={(e) => changeFormData(e)}
                            >
                              <option value="">---Select---</option>
                              <option value="Local" selected={medicineDetails?.medicine_type === "Local"}>Local</option>
                              <option value="Foreign" selected={medicineDetails?.medicine_type === "Foreign"}>Foreign</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Manufacturer's price
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              step="any"
                              // onChange={(e) => changeFormData(e)}
                              onChange={(e) => {
                                // handleFormChange(e);
                                calculateFinalPrice(e.target.value, updateData?.markup_rate ? updateData?.markup_rate : medicineDetails?.markup_rate);
                              }}
                              value={
                                updateData?.manufacturer_price
                                  ? updateData?.manufacturer_price
                                  : medicineDetails?.manufacturer_price
                              }
                              name="manufacturer_price"
                              className="form-control"
                              placeholder="Ex : 349"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Product stock
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              onChange={(e) => changeFormData(e)}
                              value={
                                updateData?.quantity
                                  ? updateData?.quantity
                                  : medicineDetails?.quantity
                              }
                              name="quantity"
                              className="form-control"
                              placeholder="Ex : 100"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Markup rate <span id="tax_symbol">(%)</span>
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000}
                              step="0.01"
                              // onChange={(e) => changeFormData(e)}
                              onChange={(e) => {
                                // handleFormChange(e);
                                calculateFinalPrice(
                                  updateData?.manufacturer_price ? updateData?.manufacturer_price : medicineDetails?.manufacturer_price,
                                  e.target.value
                                );
                              }}
                              value={
                                updateData?.markup_rate
                                  ? updateData?.markup_rate
                                  : medicineDetails?.markup_rate
                              }
                              name="markup_rate"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group mb-0">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlInput1"
                            >
                              Final Unit price
                            </label>
                            <input
                              type="number"
                              min={0}
                              max={100000000}
                              step="any"
                              onChange={(e) => changeFormData(e)}
                              value={
                                updateData?.final_price
                                  ? updateData?.final_price
                                  : medicineDetails?.final_price
                              }
                              name="final_price"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          
                          
                          name="expiry_date"
                          className="form-control"
                          required
                          onChange={(e) => changeFormData(e)}
                          value={
                            updateData?.expiry_date
                              ? updateData?.expiry_date
                              : medicineDetails?.expiry_date
                          }
                        />
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="btn--container justify-content-end">
                  {!updatingMedicine ? (
                    <button type="submit" className="btn btn--primary">
                      Update
                    </button>
                  ) : (
                    <RotatingLines
                      visible={true}
                      height="96"
                      width="96"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                </div>
              </div>
            </form>
          )
        }
      </div>
    </main>
  );
};

export default MedicineDetails;
