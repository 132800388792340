import React from 'react'

const ExpenseDetails = ({expense, setExpenseModal}) => {
  return (
    <div className="modal fade" id="add-customer">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add new expense</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setExpenseModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Expense Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={expense?.expense_type}
                      disabled
                    />
                    
                  </div>
                </div>
                
                
              </div>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">Amount <span className="input-label-secondary text-danger">
                        *
                      </span></label>
                    
                    <input
                      type="number"
                      step="0.01"
                      name="amount"
                      className="form-control"
                      disabled
                      value={expense?.amount}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Description
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control"
                      disabled
                      value={expense?.description}
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* <div className="btn--container justify-content-end">
                {!recordingExpense ? (
                  <>
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="submit"
                      id="submit_new_customer"
                      className="btn btn--primary"
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpenseDetails