import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { api_url } from "../../utils/data";
import axios from "axios";
import NoData from "../../components/NoData";
import ReactPaginate from "react-paginate";
import { MdDeleteOutline, MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";

const Suppliers = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = suppliers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(suppliers.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % suppliers.length;
    setItemOffset(newOffset);
  };

  const filterSupplierList = (search) => {
    if (search === "") {
      setSuppliers(suppliersList);
    } else {
      const filteredSuppliersList = suppliers.filter((supplier) => {
        return search.toLowerCase() === ""
          ? supplier
          : supplier.company_name.toLowerCase().includes(search.toLowerCase());
      });
      setSuppliers(filteredSuppliersList);
      
    }
  };

  const getSuppliersList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios
        .get(`${api_url}/supplier-management/`, config)
        .then((response) => {
          setSuppliersList(response.data);
          setSuppliers(response.data);
          setLoadingSuppliers(false);
        });
    } catch (error) {
      setLoadingSuppliers(false);
      toast.error("An error occured please try again");
    }
  };

  useEffect(() => {
    if(subscriptionDetails?.success){
      if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
        window.location.href = "/upgrade-subscription"
      }else{
        getSuppliersList();
      }
    }else{
      window.location.href="/no-subscription"
    }
    
  }, []);

  const deleteSupplier = (supplier_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (result.isConfirmed) {
        try {
          await axios
            .delete(
              `${api_url}/supplier-management/supplier/${supplier_id}/`,
              config
            )
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Supplier has been deleted.",
                icon: "success",
              }).then(() => {
                getSuppliersList();
              });
            });
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 500) {
            return toast.error("Internal server error");
          }
          if (error?.response?.status === 400) {
            for (const property in error.response.data) {
              if (property !== "0") {
                toast.error(`${property}: ${error.response.data[property]}`);
              } else {
                toast.error(`${error.response.data[property]}`);
              }
            }
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    });
  };

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--20"
                alt=""
              />
            </span>
            <span>
              Suppliers List{" "}
              {/* <span className="badge badge-soft-primary ml-2 badge-pill">
                19
              </span> */}
            </span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card--header">
              <form>
                <div className="input-group">
                  <input
                    id="datatableSearch_"
                    type="search"
                    name="search"
                    className="form-control"
                    placeholder="Search by Company Name "
                    aria-label="Search"
                    defaultValue=""
                    required=""
                    onChange={(e) => filterSupplierList(e.target.value)}
                  />
                </div>
              </form>
              <div>
                <Link to="/suppliers/new" className="btn btn--primary py-2">
                  <span className="text">Add New Supplier</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            {loadingSuppliers ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  strokeColor="blue"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <table className="table table-borderless table-hover table-align-middle m-0 text-14px">
                <thead className="thead-light">
                  <tr className="word-nobreak">
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Company Name</th>
                    <th>Contact Name</th>
                    <th className="text-center">Address</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((supplier, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="table-column-pl-0">
                        <a className="product-list-media">
                          <div className="table--media-body">
                            <h5 className="title m-0">
                              {supplier.company_name}
                            </h5>
                          </div>
                        </a>
                      </td>
                      <td>
                        <h5 className="m-0">
                          <a>
                            <span className="__cf_email__">
                              {supplier.contact_name}
                            </span>
                          </a>
                        </h5>
                      </td>
                      <td>
                        <div className="text-center">
                          <span>{supplier.address}</span>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">{supplier.email}</div>
                      </td>

                      <td>
                        <div className="btn--container justify-content-center">
                          <Link
                            className="action-btn"
                            to={`/supplier/${supplier?.id}/`}
                          >
                            <MdEdit />
                          </Link>
                          <Link
                            className="action-btn"
                            to={`/customer-sales/${supplier.id}`}
                          >
                            <MdOutlineRemoveRedEye />
                          </Link>
                          <a
                            className="action-btn btn--danger btn-outline-danger"
                            href="javascript:"
                            onClick={() => deleteSupplier(supplier.id)}
                          >
                            <MdDeleteOutline />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="card-footer">
            <nav>
              <ReactPaginate
                breakLabel="..."
                nextLabel="›"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="‹"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="page-item active"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
              />
            </nav>
          </div>
          {suppliers.length <= 0 && !loadingSuppliers && <NoData />}
        </div>
      </div>
    </main>
  );
};

export default Suppliers;
