import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAuthenticate } from "../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";
import { toast } from "react-toastify";

const SuperAdminDashboard = () => {
  const {authToken} = useAuthenticate()
  const [dashboardData, setDashboardData] = useState({});
  const [loadingDashboardData, setLoadingDashboardData] = useState(true)

  const getDashboardData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    await axios
      .get(`${api_url}/accounts/superadmin-dashboard/`, config)
      .then((response) => {
        
        
        setDashboardData(response.data);
        
        setLoadingDashboardData(false);
      })
      .catch((error) => {
        setLoadingDashboardData(false);
        if (error?.response?.status === 500) {
          return toast.error("Internal server error");
        }
        if (error?.response?.status === 400) {
          console.log(error);
          for (const property in error.response.data) {
            if (property !== "0") {
              toast.error(`${property}: ${error.response.data[property]}`);
            } else {
              toast.error(`${error.response.data[property]}`);
            }
          }
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(()=>{
    getDashboardData()
  },[])

  if(loadingDashboardData){
    return <Loading />
  }else{
    return (
      <div className="footer-offset">
        <Helmet>
          <title>RxUltimate | SuperAdmin Dashboard</title>
        </Helmet>
        <main id="content" role="main" className="main main-page pointer-event">
          <div className="content container-fluid">
            <div className="page-header mb-0 pb-2 border-0">
              <h1 className="page-header-title">Dashboard</h1>
              <p className="welcome-msg">
                See your high-level summary of key project metrics and status here
              </p>
            </div>
            
            {/* DASHBOARD CARDS */}
            <div className="card mb-10px">
              <div className="card-body">
                
                <div className="row g-2" id="order_stats">
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="/super-admin/pharmacies/list">
                      <h6 className="subtitle">Registered Pharmacies</h6>
                      <h2 className="title">{dashboardData?.pharmacies}</h2>
                      
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="">
                      <h6 className="subtitle">Expired Subscriptions</h6>
                      <h2 className="title">{dashboardData?.expired_subscriptions}</h2>
                      
                    </Link>
                  </div>
                  {/* <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="">
                      <h6 className="subtitle">Pending Requests</h6>
                      <h2 className="title">0</h2>
                      
                    </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <Link className="dashboard--card h-100" to="">
                      <h6 className="subtitle">Out of stock medicine</h6>
                      <h2 className="title">0</h2>
                      
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
  
            
  
          </div>
        </main>
      </div>
    );
  }
};

export default SuperAdminDashboard;
