import React, { useEffect, useState } from 'react'

const Timer = () => {
    const [time,setTime] =useState(new Date())

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    useEffect(()=>{
        setInterval(()=>setTime(new Date()),1000)
    },[])
  return (
    <div className='d-flex' style={{justifyContent:"space-around"}}>
        <div>
          <p className='mb-0'>Time:</p>
          <p>
          {time.toLocaleTimeString()}
          </p>
        </div>
        <div>
        <p className='mb-0'>Date:</p>
          <p>
          {time.toLocaleDateString(undefined, options)}
          </p>
        </div>
        
    </div>
  )
}

export default Timer