import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {MdOutlineKeyboardArrowRight,MdOutlineKeyboardArrowDown} from 'react-icons/md'

const SidemenuItem = ({ menuItem }) => {
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const toggleSubMenu = () => {
    setOpenSubmenu(!openSubmenu);
  };
  return (
    <li className="navbar-vertical-aside-has-menu">
      {!menuItem?.submenus ? (
        <NavLink
          className="js-navbar-vertical-aside-menu-link nav-link"
          to={menuItem.path}
        >
          {menuItem.icon}
          {/* <AiFillHome className="tio-home-vs-1-outlined nav-icon" /> */}
          <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
            {menuItem.name}
          </span>
        </NavLink>
      ) : (
        <li className="navbar-vertical-aside-has-menu" onClick={toggleSubMenu} style={{cursor:"pointer"}}>
          <div
            className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
            to="#"
          >
            {menuItem.icon}
            <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
              {menuItem.name}
            </span>
            {
                openSubmenu ? (
                    <MdOutlineKeyboardArrowDown onClick={toggleSubMenu} style={{fontSize:"20px", padding: "0px 0px 0px 10px", transition:".5s ease"}} />
                    ):(
                    <MdOutlineKeyboardArrowRight onClick={toggleSubMenu} style={{fontSize:"20px", padding: "0px 0px 0px 10px", transition:".5s ease"}} />
                    )
            }
          </div>

                <ul
                    className="nav nav-sub"
                    style={{display: openSubmenu ? "block":"none"}}
                >   
                        {
                            menuItem?.submenus.map((submenu, index) => (
                                <li className="nav-item" key={index}>
                                <NavLink
                                    className="nav-link"
                                    to={submenu?.path}
                                >
                                    <span className="text-truncate">{submenu?.name}</span>
                                </NavLink>
                                </li>
                            ))
                        }
                            
                    
                </ul>
          
        </li>
      )}
    </li>
  );
};

export default SidemenuItem;
