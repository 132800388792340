import React, { useEffect, useRef, useState } from "react";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { api_url } from "../../utils/data";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import NoData from "../../components/NoData";
import { useAuthenticate } from "../../context/AuthContext";

const SalesHistory = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const salesListRef = useRef();
  const [loadingSaleHistory, setLoadingSaleHistory] = useState(false);
  const [saleHistory, setSaleHistory] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = salesList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(salesList.length / itemsPerPage);
  const [deletingSale, setDeletingSale] = useState(false)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % salesList.length;
    setItemOffset(newOffset);
  };

  const filterSaleList = (search) => {
    if (search === "") {
      setSalesList(saleHistory);
    } else {
      const filteredSaleList = salesList.filter((sale) => {
        return search.toLowerCase() === ""
          ? sale
          : sale.sale_id.toLowerCase().includes(search.toLowerCase());
      });
      setSalesList(filteredSaleList);
    }
  };

  const getSaleHistory = async () => {
    setLoadingSaleHistory(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/sales/`, config)
      .then((response) => {
        setSaleHistory(response.data);
        setSalesList(response.data);
        setLoadingSaleHistory(false);
      })
      .catch((error) => {
        setLoadingSaleHistory(false);
      });
  };

  const filterSaleHistory = async (e) => {
    e.preventDefault();
    if (filterStartDate === "" && filterEndDate === "") {
      toast.error("Please specify the start date and end date");
      return false;
    }
    setLoadingSaleHistory(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/sales/filter/${filterStartDate}/${filterEndDate}/`,
        config
      )
      .then((response) => {
        setSaleHistory(response.data);
        setSalesList(response.data);
        setLoadingSaleHistory(false);
      })
      .catch((error) => {
        setLoadingSaleHistory(false);
      });
  };

  const clearData = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    getSaleHistory();
  };

  const deleteSale = (sale_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingSale(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${api_url}/sales/${sale_id}/details`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Sale has been deleted.",
                icon: "success",
              }).then(() => {
                getSaleHistory();
                setDeletingSale(false)
              });
            });
        } catch (error) {
          setDeletingSale(false)
          toast.error("Something went wrong.");
        }
      }
    });
  };

  useEffect(() => {
    
    getSaleHistory();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                className="w--20"
                alt=""
              />
            </span>
            <span className="">All Sales</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header shadow flex-wrap p-20px border-0">
            <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
            <form onSubmit={(e) => filterSaleHistory(e)} className="w-100 mt-5">
              <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="start_date">
                      Start Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={filterStartDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterStartDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="end_date">
                      End Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={filterEndDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-12 col-lg-4 __btn-row">
                  <a
                    id=""
                    className="btn w-100 btn--reset min-h-45px d-flex"
                    onClick={() => clearData()}
                  >
                    Clear
                  </a>
                  <button
                    type="submit"
                    id="show_filter_data"
                    className="btn w-100 btn--primary min-h-45px"
                  >
                    Show data
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <form>
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Ex : Search by invoice ID"
                      aria-label="Search"
                      onChange={(e) => filterSaleList(e.target.value)}
                    />
                  </div>
                </form>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary">Print List</button>
                  )}
                  content={() => salesListRef.current}
                />
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              {loadingSaleHistory ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2rem",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">Invoice ID</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th className="text-right">Total amount</th>
                      <th className="text-right">Amount Paid</th>
                      <th className="text-right">Outstanding balance</th>
                      <th>
                        <div className="text-center">Payment Status</div>
                      </th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody id="set-rows">
                    {currentItems.map((sale, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td className="table-column-pl-0">{sale.sale_id}</td>
                        <td>{sale.date}</td>

                        <td>
                          {!sale?.customer ? (
                            "Walk in customer"
                          ) : (
                            <>
                              <div>
                                <a
                                  className="text-body text-capitalize font-medium"
                                  href=""
                                >
                                  {sale?.customer?.customer_name}
                                </a>
                              </div>
                              <div className="text-sm">
                                <Link
                                  to={`tel:${sale?.customer?.customer_phone_number}`}
                                >
                                  {sale?.customer?.customer_phone_number}
                                </Link>
                              </div>
                            </>
                          )}
                        </td>

                        <td className="text-right">GHS {sale.total_cost}</td>
                        <td className="text-right">GHS {sale.amount_paid}</td>
                        <td className="text-right">
                          GHS {sale.outstanding_balance}
                        </td>
                        <td className="text-capitalize text-center">
                          {sale.payment_status === "Full Payment" ? (
                            <span className="badge badge-soft-success">
                              {sale.payment_status}
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger">
                              {sale.payment_status}
                            </span>
                          )}
                        </td>
                        <td>
                          {
                            deletingSale ? (
                              <div className="d-flex align-items-center justify-content-center col-sm-12">
                                <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  color="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            ):(

                              <div className="btn--container justify-content-center">
                                <Link
                                  className="action-btn btn--primary btn-outline-primary"
                                  to={`/sales/${sale?.id}/details`}
                                >
                                  <MdOutlineRemoveRedEye />
                                </Link>
    
                                <Link
                                  className="action-btn btn--danger btn-outline-danger"
                                  onClick={() => deleteSale(sale.id)}
                                >
                                  <MdDeleteOutline />
                                </Link>
                              </div>
                            )
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {salesList.length <= 0 && !loadingSaleHistory && (
                <NoData />
              )}
            </div>

            {/* LIST TO PRINT */}
            <div style={{ display: "none" }}>
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
                ref={salesListRef}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Invoice ID</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th className="text-right">Total amount</th>
                    <th className="text-right">Amount Paid</th>
                    <th className="text-right">Outstanding balance</th>
                    <th>
                      <div className="text-center">Payment Status</div>
                    </th>
                  </tr>
                </thead>

                <tbody id="set-rows">
                  {salesList.map((sale, index) => (
                    <tr key={index} className="status-delivered class-all">
                      <td className="">{index + 1}</td>
                      <td className="table-column-pl-0">{sale.sale_id}</td>
                      <td>{sale.date}</td>

                      <td>
                        {!sale?.customer ? (
                          "Walk in customer"
                        ) : (
                          <>
                            <div>
                              <a
                                className="text-body text-capitalize font-medium"
                                href=""
                              >
                                {sale?.customer?.customer_name}
                              </a>
                            </div>
                            <div className="text-sm">
                              <Link
                                to={`tel:${sale?.customer?.customer_phone_number}`}
                              >
                                {sale?.customer?.customer_phone_number}
                              </Link>
                            </div>
                          </>
                        )}
                      </td>

                      <td className="text-right">GHS {sale.total_cost}</td>
                      <td className="text-right">GHS {sale.amount_paid}</td>
                      <td className="text-right">
                        GHS {sale.outstanding_balance}
                      </td>
                      <td className="text-capitalize text-center">
                        {sale.payment_status === "Full Payment" ? (
                          <span className="badge badge-soft-success">
                            {sale.payment_status}
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger">
                            {sale.payment_status}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <nav>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="›"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="‹"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="page-item active"
                  pageLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SalesHistory;
