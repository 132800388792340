import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthenticate } from '../../../context/AuthContext'

const PackagesList = () => {
  const {packages} = useAuthenticate()
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">Packages</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-body p-5px">
            <div className="order-top">
              {/* <div className="card--header">
                <form
                  
                >
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Search by Package Name"
                      aria-label="Search"
                      required
                      
                    />
                  </div>
                </form>

                <div className="hs-unfold mr-2">
                    <div to="/super-admin/pharmacies/new" className="btn btn-secondary">Add New Package</div>                  
                </div>

              </div> */}
            </div>
            <div className="table-responsive datatable-custom">
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
              >
                <thead className="thead-light">
                  
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Package Name</th>
                    <th>Price</th>
                    <th>Interval</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {
                    packages?.map((package_item, index)=>(
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{package_item?.name}</td>
                        <td>{package_item?.price}</td>
                        <td>{package_item?.duration} days</td>
                      </tr>
                    ))
                  }
                  
                </tbody>
              </table>
             
            </div>
            
          </div>
          {/* <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              
              <nav>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="›"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="‹"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="page-item active"
                  pageLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
                
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  )
}

export default PackagesList