import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../utils/data";
import Swal from "sweetalert2";

const AddSupplier = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [creatingSupplier, setCreatingSupplier] = useState(false);
  const [supplierData, setSupplierData] = useState({});

  const handleFormChange = (e) => {
    setSupplierData({
      ...supplierData,
      [e.target.name]: e.target.value,
    });
  };

  const createSupplier = async (e) => {
    e.preventDefault();
    setCreatingSupplier(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const body = JSON.stringify(supplierData);
    try {
      await axios
        .post(`${api_url}/supplier-management/`, body, config)
        .then((response) => {
          if (response.status === 201) {
            Swal.fire({
              title: "Created!",
              text: "Supplier has been created.",
              icon: "success",
            }).then(() => {
              window.location.href = "/suppliers/list";
            });
            setCreatingSupplier(false);
          }
        });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 500) {
        return toast.error("Internal server error");
      }
      if (error?.response?.status === 400) {
        for (const property in error.response.data) {
          if (property !== "0") {
            toast.error(`${property} error: ${error.response.data[property]}`);
          } else {
            toast.error(`${error.response.data[property]}`);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
      setCreatingSupplier(false);
    }
  };

  useEffect(()=>{
    if(subscriptionDetails?.success){
      if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
        window.location.href = "/upgrade-subscription"
      }
    }else{
      window.location.href="/no-subscription"
    }
  }, [])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Add New Customer </span>
          </h1>
        </div>
        <form onSubmit={(e) => createSupplier(e)} style={{ textAlign: "left" }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>General Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-12">
                  <div className="row g-3">
                    <div className="col-md-4">
                      <label className="form-label">Company Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="company_name"
                        className="form-control"
                        id="name"
                        placeholder="Ex : John Doe"
                        onChange={(e) => handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Contact Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="contact_name"
                        className="form-control"
                        id="name"
                        placeholder="Ex : John Doe"
                        onChange={(e) => handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Phone</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="phone"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Ex : +233********"
                        onChange={(e) => handleFormChange(e)}
                        required
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        name="address"
                        defaultValue=""
                        className="form-control"
                        id="email"
                        placeholder="Ex : Plot 2 Kwadaso"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        name="email"
                        defaultValue=""
                        className="form-control"
                        id="email"
                        placeholder="Ex : example@example.com"
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn--container justify-content-end mt-3">
            {!creatingSupplier ? (
              <>
                <button type="reset" className="btn btn--reset">
                  Reset
                </button>
                <button type="submit" className="btn btn--primary">
                  Submit
                </button>
              </>
            ) : (
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="blue"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddSupplier;
