import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";

const CategoryDetails = () => {
  const { category_id } = useParams();
  const { categoryList,authToken } = useAuthenticate();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [updateName, setUpdateName] = useState(null);
  const [updatingDetails, setUpdatingDetails] = useState(false);

  const getCategoryDetails = (category_id) => {
    setCategoryDetails(
      categoryList.find((category) => category.id === parseInt(category_id))
    );
  };

  const updateCategory = async (e) => {
    e.preventDefault();
    setUpdatingDetails(true);
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = JSON.stringify({
        "name": updateName
    })


    try {
      await axios
        .put(
          `${api_url}/inventory/medicine-categories/${category_id}/`,
          formData,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Updated!",
              text: "Category has been updated.",
              icon: "success",
            }).then(() => {
              setUpdatingDetails(false);
              window.location.reload()
            });
          }
        });
    } catch (error) {
      
      toast.error("Something went wrong.");
      setUpdatingDetails(false);
    }
  };

  useEffect(() => {
    getCategoryDetails(category_id);
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/category.png"
                className="w--24"
                alt=""
              />
            </span>
            <span> Category Update </span>
          </h1>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={(e)=>updateCategory(e)}>
              <ul className="nav nav-tabs d-inline-flex mb--n-30">
                <li className="nav-item">
                  <a
                    className="nav-link lang_link active"
                    href="#"
                    id="en-link"
                  >
                    Category Details
                  </a>
                </li>
              </ul>
              <div className="row align-items-end g-4 mt-2">
                <div className="col-sm-6 lang_form" id="en-form">
                  <label
                    className="input-label"
                    htmlFor="exampleFormControlInput1"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    maxLength={255}
                    value={updateName ? updateName : categoryDetails?.name}
                    className="form-control"
                    onChange={(e) => setUpdateName(e.target.value)}
                    required
                  />
                </div>

                
                <div className="col-12">
                  <div className="btn--container justify-content-end">
                    {!updatingDetails ? (
                      <>
                        <button type="reset" className="btn btn--reset">
                          Reset
                        </button>
                        <button type="submit" className="btn btn--primary">
                          Update
                        </button>
                      </>
                    ) : (
                      <>
                        <RotatingLines
                          visible={true}
                          height="96"
                          width="96"
                          color="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          ariaLabel="rotating-lines-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CategoryDetails;
