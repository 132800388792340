import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuthenticate } from '../../context/AuthContext'
import { api_url } from '../../utils/data'
import axios from 'axios'
import Loading from '../../components/Loading/Loading'
import { IoPrintSharp } from 'react-icons/io5'
import ArrearsPayment from './ArrearsPayment'
import Swal from 'sweetalert2'

const PurchaseDetails = () => {
    const {purchase_id} = useParams()
    const {authToken,subscriptionDetails} = useAuthenticate()
    const [purchaseDetails, setPurchaseDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(true)
    const [arrearsPaymentModal, setArrearsPaymentModal] = useState(false)


    const getPurchaseDetails = async () => {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
    
        await axios
          .get(`${api_url}/supplier-management/purchase/${purchase_id}/`, config)
          .then((response) => {
            console.log(response)
            setPurchaseDetails(response.data);
            setLoadingDetails(false)
          })
          .catch((error) => {
            setLoadingDetails(false)
          });
      }


      useEffect(()=>{
        if(subscriptionDetails?.success){
            if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
              window.location.href = "/upgrade-subscription"
            }else{
                getPurchaseDetails();
            }
          }else{
            window.location.href="/no-subscription"
          }
        
      },[])

  
  return (
    <main id="content" role="main" className="main main-page pointer-event">
        {
            loadingDetails ? (
                <Loading />
            ):(
                <div>    
                    <div className="content container-fluid">
                    <div className="page-header d-flex justify-content-between">
                        <h1 className="page-header-title">
                        <span> Purchase details </span>
                        </h1>
                    </div>
                    <div className="row" id="printableArea">
                        <div className="col-lg-12 order-print-area-left">
                        <div className="card mb-3 mb-lg-5">
                            <div className="card-header flex-wrap align-items-start border-0">
                            <div className="order-invoice-left">
                                <h3 className="">
                                <span className="mr-3">
                                    PURCHASE ID: #{purchaseDetails?.purchase_id}
                                </span>
                                
                                </h3>

                                <h3 className="">
                                <span className="mr-3">
                                    SUPPLIER: {purchaseDetails?.supplier}
                                </span>
                                
                                </h3>
                                <span>
                                <i className="tio-date-range" /> {purchaseDetails?.date}
                                
                                </span>
                            </div>
                            <div className="order-invoice-right mt-3 mt-sm-0">
                                {purchaseDetails?.amount_paid < purchaseDetails?.gross_total && (
                                <div className="btn--container ml-auto align-items-center justify-content-end">
                                    <Link
                                    className="btn btn-outline-info"
                                    onClick={()=>setArrearsPaymentModal(true)}
                                    >
                                    <i className="tio-print mr-1" /> Record Arrears Payment
                                    </Link>
                                </div>
                                )}
                                <div className="text-right mt-3 order-invoice-right-contents text-capitalize">
                                
                                <h6>
                                    <span className="text-body mr-2">
                                    Total Amount Paid :{" "}
                                    </span>
                                    <span>
                                        {purchaseDetails?.amount_paid}
                                    </span>
                                    
                                </h6>
                                <h6>
                                    <span className="text-body mr-2">
                                    Total Outstanding Balance :{" "}
                                    </span>
                                    <span>
                                        {purchaseDetails?.outstanding_balance}
                                    </span>
                                    
                                </h6>
                                </div>
                            </div>
                            </div>
                            <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-borderless table-nowrap table-align-middle card-table dataTable no-footer mb-0">
                                <thead className="thead-light">
                                    <tr>
                                    <th className="border-0">SL</th>
                                    <th className="border-0">Purchase Item</th>
                                    <th className="border-0 text-right">Unit Price</th>
                                    <th className="border-0 text-right">Quantity</th>
                                    <th className="text-right border-0">Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseDetails?.purchase_items?.map((purchaseItem, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                        <div className="media media--sm">
                                            <div className="media-body">
                                            <h5 className="line--limit-1">
                                                {purchaseItem?.item_name}
                                            </h5>
                                            
                                            </div>
                                        </div>
                                        </td>
                                        <td className="text-right">
                                        <h6>GHS {purchaseItem?.unit_cost}</h6>
                                        </td>
                                        <td className="text-right">
                                        <h6> {purchaseItem?.quantity}</h6>
                                        </td>
                                        <td className="text-right">
                                        <h5>GHS {purchaseItem?.total_cost}</h5>
                                        </td>
                                    </tr>
                                    ))}
                                    <tr>
                                    <td colSpan={12} className="td-p-0">
                                        <hr className="m-0" />
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div className="row justify-content-md-end mb-3 mt-4">
                                <div className="col-md-9 col-lg-8">
                                <dl className="row text-right justify-content-end">
                                     <dt className="col-6 text-left">
                                        <div className="ml-auto max-w-130px">Sub Total:</div>
                                    </dt>
                                    <dd className="col-6 col-xl-5 pr-5">
                                        GHS {purchaseDetails?.sub_total}
                                        <hr />
                                    </dd>

                                    <dt className="col-6 text-left">
                                        <div className="ml-auto max-w-130px">Discount(%):</div>
                                    </dt>
                                    <dd className="col-6 col-xl-5 pr-5">
                                        {purchaseDetails?.discount} %
                                        <hr />
                                    </dd>
                                    

                                    <dt className="col-6 text-left">
                                        <div className="ml-auto max-w-130px">Gross Total:</div>
                                    </dt>
                                    <dd className="col-6 col-xl-5 pr-5">
                                        GHS {purchaseDetails?.gross_total}
                                        <hr />
                                    </dd>
                                </dl>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    </div>

                    {/* ARREARS PAYMENTS */}
                    <div className="content container-fluid">
                    <div className="page-header d-flex justify-content-between">
                        <h1 className="page-header-title">
                        <span> Payments History </span>
                        </h1>
                    </div>
                    <div className="row" id="printableArea">
                        <div className="col-lg-12 order-print-area-left">
                        <div className="card mb-3 mb-lg-5">
                            
                            <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-borderless table-nowrap table-align-middle card-table dataTable no-footer mb-0">
                                <thead className="thead-light">
                                    <tr>
                                    <th className="border-0">SL</th>
                                    <th className="border-0">Payment ID</th>
                                    <th className="border-0 ">Amount Paid</th>
                                    <th className="border-0 ">Outstanding Balance</th>
                                    <th className=" border-0">Date Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    purchaseDetails?.purchase_payments?.map((payment, index)=>(
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                            {payment.payment_id}
                                            </td>
                                            <td>
                                            GHS {payment.amount_paid}
                                            </td>
                                            <td>
                                            GHS {payment.outstanding_balance}
                                            </td>
                                            <td>
                                            {payment.date}
                                            </td>
                                            
                                        </tr>

                                    ))
                                    }
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    </div>
                    {
                    arrearsPaymentModal && <ArrearsPayment purchase = {purchaseDetails} setReceivePaymentModal={setArrearsPaymentModal} />
                    }
                </div>
            )
        }
        
        
      </main>
  )
}

export default PurchaseDetails