import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";

const Profile = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const [profileDetailsUpdate, setProfileDetailsUpdate] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [gettingProfileDetails, setGettingProfileDetails] = useState(true)

  const getStaffProfile = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(`${api_url}/accounts/profile/`, config)
      .then((response) => {
        setProfileDetails(response.data);
        setGettingProfileDetails(false)
      })
      .catch((error) => {
        setGettingProfileDetails(false);
        toast.error("An error occured. Please try again.");
      });
  };

  const handleFormChange = (e) => {
    setProfileDetailsUpdate({
      ...profileDetailsUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    setUpdatingDetails(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const formData = new FormData();
    Object.keys(profileDetailsUpdate).forEach((key) => {
      formData.append(key, profileDetailsUpdate[key]);
    });

    await axios
      .put(`${api_url}/accounts/profile/`, formData, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Profile updated",
          text: "Profile updated",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setUpdatingDetails(false);
        toast.error("An error occured. Please try again.");
      });
  };

  const changePassword = async (e) => {
    e.preventDefault()
    if(newPassword === null){
      toast.error("Please enter a new password");
      return false
    }else if (newPassword !== confirmPassword){
      toast.error("The passwords do not match. Please try again.");
      return false;
    }else {
      setChangingPassword(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
  
      const body = JSON.stringify({
        "new_password": newPassword
      })

      await axios
        .put(`${api_url}/accounts/change-password/`,body, config)
        .then((response) => {
          setChangingPassword(false)
          Swal.fire({
            icon:"success",
            title:"Password Changed",
            text:"Password has been changed successfully"
          }).then(()=>{
            window.location.reload()
          })
        })
        .catch((error) => {
          setChangingPassword(false)
          toast.error("An error occured. Please try again.");
        });
    }
  }

  useEffect(() => {
    
    getStaffProfile();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <Helmet>
        <title>Stewards Pharmacy | Profile</title>
      </Helmet>
      {
        gettingProfileDetails ? (
          <Loading />
        ):(
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-sm mb-2 mb-sm-0">
                  <h1 className="page-header-title">Profile</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
                  <button
                    type="button"
                    className="navbar-toggler btn btn-block btn-white mb-3"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="navbarVerticalNavMenu"
                    data-toggle="collapse"
                    data-target="#navbarVerticalNavMenu"
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <span className="h5 mb-0">Nav menu</span>
                      <span className="navbar-toggle-default">
                        <i className="tio-menu-hamburger" />
                      </span>
                      <span className="navbar-toggle-toggled">
                        <i className="tio-clear" />
                      </span>
                    </span>
                  </button>
                  <div
                    id="navbarVerticalNavMenu"
                    className="collapse navbar-collapse"
                  >
                    <ul
                      id="navbarSettings"
                      className="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="javascript:"
                          id="generalSection"
                        >
                          <FaUser className="nav-icon" />
                          Basic information
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <form onSubmit={(e) => updateDetails(e)} id="admin-settings-form">
                  <div className="card mb-3 mb-lg-5" id="generalDiv">
                    <div className="profile-cover">
                      <div className="profile-cover-img-wrapper" />
                    </div>
                    <label
                      className="avatar avatar-xxl avatar-circle avatar-border-lg avatar-uploader profile-cover-avatar"
                      htmlFor="avatarUploader"
                    >
                      {profileDetailsUpdate?.profile_picture ? (
                        <img
                          id="viewer"
                          className="avatar-img"
                          src={URL.createObjectURL(
                            profileDetailsUpdate?.profile_picture
                          )}
                          alt="user"
                        />
                      ) : (
                        <img
                          id="viewer"
                          className="avatar-img"
                          src={`https://apiv1.rxultimate.com/${profileDetails?.profile_picture}`}
                          alt="user"
                        />
                      )}
                      <input
                        type="file"
                        name="profile_picture"
                        className="js-file-attach avatar-uploader-input"
                        id="customFileEg1"
                        accept=".jpg, .png, .jpeg"
                        onChange={(e) =>
                          setProfileDetailsUpdate({
                            ...profileDetailsUpdate,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                      />
                      <label
                        className="avatar-uploader-trigger"
                        htmlFor="customFileEg1"
                      >
                        <i className="tio-edit avatar-uploader-icon shadow-soft">
                          <MdEdit />
                        </i>
                      </label>
                    </label>
                  </div>
                  <div className="card mb-3 mb-lg-5">
                    <div className="card-header">
                      <h2 className="card-title h4">
                        <i className="tio-info" /> Basic information
                      </h2>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <label
                          htmlFor="firstNameLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          First name
                          <i
                            className="tio-help-outlined text-body ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Display name"
                          />
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-down-break">
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              id="firstNameLabel"
                              placeholder="Your first name"
                              aria-label="Your first name"
                              value={
                                profileDetailsUpdate?.first_name
                                  ? profileDetailsUpdate?.first_name
                                  : profileDetails?.first_name
                              }
                              onChange={(e) => handleFormChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="firstNameLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Last name
                          <i
                            className="tio-help-outlined text-body ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Display name"
                          />
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-down-break">
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              id="fullNameLabel"
                              placeholder="Your last name"
                              aria-label="Your last name"
                              value={
                                profileDetailsUpdate?.last_name
                                  ? profileDetailsUpdate?.last_name
                                  : profileDetails?.last_name
                              }
                              onChange={(e) => handleFormChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="phoneLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Phone
                          
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="js-masked-input form-control"
                            name="phone_number"
                            id="phoneLabel"
                            value={
                              profileDetailsUpdate?.phone_number
                                ? profileDetailsUpdate?.phone_number
                                : profileDetails?.phone_number
                            }
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="newEmailLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="newEmailLabel"
                            placeholder="Enter new email address"
                            aria-label="Enter new email address"
                            value={
                              profileDetailsUpdate?.email
                                ? profileDetailsUpdate?.email
                                : profileDetails?.email
                            }
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {updatingDetails ? (
                          <RotatingLines
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          <button type="submit" className="btn btn-primary">
                            Save changes
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <div id="passwordDiv" className="card mb-3 mb-lg-5">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="tio-lock" /> Change your password
                    </h4>
                  </div>
                  <div className="card-body">
                    <form
                      id="changePasswordForm"
                      onSubmit={(e)=>changePassword(e)}
                    >
                      
                      <div className="row form-group">
                        <label
                          htmlFor="newPassword"
                          className="col-sm-3 col-form-label input-label"
                        >
                          New password
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="password"
                            className="js-pwstrength form-control"
                            name="password"
                            id="newPassword"
                            placeholder="Enter new password"
                            aria-label="Enter new password"
                            onChange={(e)=>setNewPassword(e.target.value)}
                            required
                          />
                          <p
                            id="passwordStrengthVerdict"
                            className="form-text mb-2"
                          />
                          <div id="passwordStrengthProgress" />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="confirmNewPasswordLabel"
                          className="col-sm-3 col-form-label input-label"
                        >
                          Confirm password
                        </label>
                        <div className="col-sm-9">
                          <div className="mb-3">
                            <input
                              type="password"
                              className="form-control"
                              name="confirm_password"
                              id="confirmNewPasswordLabel"
                              placeholder="Confirm your new password"
                              aria-label="Confirm your new password"
                              required
                              onChange={(e)=>setConfirmPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {changingPassword ? (
                          <RotatingLines
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          <button
                            type="submit"
                            
                            className="btn btn-primary"
                          >
                            Save Changes
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div id="stickyBlockEndPoint" />
              </div>
            </div>
          </div>
        )
      }
    </main>
  );
};

export default Profile;
