import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api_url, databaseUrl } from "../../utils/data";
import axios from "axios";
import { useAuthenticate } from "../../context/AuthContext";
import { useParams } from "react-router-dom";

const CustomerDetails = () => {
  const { customer_id } = useParams();
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [customerData, setCustomerData] = useState({});
  const [customerUpdateData, setCustomerUpdateData] = useState({});
  const [updatingCustomer, setUpdatingCustomer] = useState(false);
  const [gettingCustomer, setGettingCustomer] = useState(true);

  const handleFormChange = (e) => {
    setCustomerUpdateData({
      ...customerUpdateData,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomer = async () => {
    setGettingCustomer(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const body = JSON.stringify(customerUpdateData);
    try {
      await axios
        .get(
          `${api_url}/accounts/pharmacy-customers/${customer_id}/`,
          config
        )
        .then((response) => {
            console.log(response.data);
          setCustomerData(response.data);
          setGettingCustomer(false);
        });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 500) {
        return toast.error("Internal server error");
      }
      if (error?.response?.status === 400) {
        for (const property in error.response.data) {
          if (property !== "0") {
            toast.error(`${property} error: ${error.response.data[property]}`);
          } else {
            toast.error(`${error.response.data[property]}`);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
      setGettingCustomer(false);
    }
  };

  const updateCustomer = async (e) => {
    e.preventDefault();
    setUpdatingCustomer(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const body = JSON.stringify(customerUpdateData);
    try {
      await axios
        .put(
          `${api_url}/accounts/pharmacy-customers/${customer_id}/`,
          body,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Created!",
              text: "Customer has been updated.",
              icon: "success",
            }).then(() => {
              getCustomer();
            });
            setUpdatingCustomer(false);
          }
        });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 500) {
        return toast.error("Internal server error");
      }
      if (error?.response?.status === 400) {
        for (const property in error.response.data) {
          if (property !== "0") {
            toast.error(`${property} error: ${error.response.data[property]}`);
          } else {
            toast.error(`${error.response.data[property]}`);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
      setUpdatingCustomer(false);
    }
  };

  useEffect(() => {
    
    getCustomer();
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Add New Customer </span>
          </h1>
        </div>
        <form onSubmit={(e) => updateCustomer(e)} style={{ textAlign: "left" }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>General Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-12">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">Full Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="customer_name"
                        className="form-control"
                        id="name"
                        value={
                          customerUpdateData?.customer_name
                            ? customerUpdateData?.customer_name
                            : customerData?.customer_name
                        }
                        placeholder="Ex : John Doe"
                        onChange={(e) => handleFormChange(e)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Phone</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="customer_phone_number"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Ex : +233********"
                        onChange={(e) => handleFormChange(e)}
                        required
                        value={
                          customerUpdateData?.customer_phone_number
                            ? customerUpdateData?.customer_phone_number
                            : customerData?.customer_phone_number
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        name="customer_email"
                        defaultValue=""
                        className="form-control"
                        id="email"
                        placeholder="Ex : example@example.com"
                        onChange={(e) => handleFormChange(e)}
                        value={
                          customerUpdateData?.customer_email
                            ? customerUpdateData?.customer_email
                            : customerData?.customer_email
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn--container justify-content-end mt-3">
            {!updatingCustomer ? (
              <>
                <button type="reset" className="btn btn--reset">
                  Reset
                </button>
                <button type="submit" className="btn btn--primary">
                  Submit
                </button>
              </>
            ) : (
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="blue"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
};

export default CustomerDetails;
