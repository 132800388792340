import React,{useEffect, useState} from "react";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Loading from "../../components/Loading/Loading";
import NoData from "../../components/NoData";


const Employees = () => {
  const {authToken, subscriptionDetails} = useAuthenticate()
  const [employeeList, setEmployeeList] = useState([])
  const [employees, setEmployees] = useState([]);
  const [gettingEmployees, setGettingEmployees] = useState(true)
  
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = employees.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(employees.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % employees.length;
    setItemOffset(newOffset);
  };

  const filterEmployeeList = (search) => {
    
    if (search === "") {
      setEmployees(employeeList);
    } else {
      const filteredEmployeeList = employees.filter((employee) => {
        return employee?.full_name?.toLowerCase().includes(search) ||
              employee?.phone_number?.toLowerCase().includes(search) || 
              employee?.email?.toLowerCase().includes(search);
      });
      setEmployees(filteredEmployeeList);
    }
  };

  const getEmployeeList = async () => {

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios.get(`${api_url}/staff-management/employees/`, config).then((response)=>{
        
        setEmployeeList(response.data)
        setEmployees(response.data)
        setGettingEmployees(false)
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
      setGettingEmployees(false);
    }

  }

  const deleteEmployee = async (employee_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${api_url}/staff-management/employees/${employee_id}/`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Employee has been deleted.",
                icon: "success",
              }).then(() => {
                window.location.reload()
              });
            });
        } catch (error) {
          
          toast.error("Something went wrong.");
        }
      }
    });
  }

  useEffect(()=>{
    
    getEmployeeList()
  },[])

  if(gettingEmployees){
    return <Loading />
  }else{
    return (
      <main id="content" role="main" className="main main-page pointer-event">
        <div className="content container-fluid">
          <div className="page-header">
            <h1 className="page-header-title">
              <span className="page-header-icon">
                <img
                  src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                  className="w--24"
                  alt="mail"
                />
              </span>
              <span>
                Employee List
                <span className="badge badge-soft-primary ml-2">{employeeList.length}</span>
              </span>
            </h1>
          </div>
          <div className="card">
            <div className="card-header border-0">
              <div className="card--header">
                <form>
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Search by Name or Phone or Email"
                      aria-label="Search"
                      defaultValue=""
                      required
                      onChange={(e)=>filterEmployeeList(e.target.value)}
                    />
                  </div>
                </form>
                
                <div>
                  <Link
                    to="/staff-management/employees/new"
                    className="btn btn--primary py-2"
                  >
                    <span className="text">Add New Employee</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body px-0 pt-0">
              <div className="table-responsive">
                <table
                  id="datatable"
                  style={{ textAlign: "left" }}
                  className="table table-borderless table-hover table-align-middle m-0 text-14px"
                >
                  <thead className="thead-light">
                    <tr>
                      <th>SL</th>
                      <th>Name</th>
                      <th>Contact Info</th>
                      <th>Role</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      currentItems.map((employee, index)=>(
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td className="text-capitalize">
                              <div className="table--media">
                                <img
                                  className="rounded-full"
                                  src={`https://apiv1.rxultimate.com/${employee?.profile_picture}`}
                                  alt="img"
                                />
                                <div className="table--media-body">
                                  <h5 className="title">{employee.full_name}</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <h5 className="m-0">
                                <a
                                  href=""
                                  className="text-hover"
                                >
                                  <span
                                    className="__cf_email__"
                                  >
                                    {employee.email}
                                  </span>
                                </a>
                              </h5>
                              <div>
                                <Link href="" className="text-hover">
                                  {employee.phone_number}
                                </Link>
                              </div>
                            </td>
                            <td>
                              <span className="badge badge-soft-info py-2 px-3 font-medium">
                                {employee?.position}
                              </span>
                            </td>
                            <td>
                              <div className="btn--container justify-content-center">
                                <Link
                                  to={`/staff-management/employees/${employee.id}`}
                                  className="action-btn"
                                  title="Edit"
                                >
                                  <MdEdit />
                                </Link>
                                <Link
                                onClick={()=>deleteEmployee(employee.id)}
                                  className="action-btn btn--danger btn-outline-danger"                                
                                >
                                  <MdDeleteOutline />
                                </Link>
                              </div>
                            </td>
                          </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              {employees.length <= 0 && (
                  <NoData />
                )}
            </div>
            <div className="card-footer border-0">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    
                    <nav>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="›"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="‹"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        activeClassName="page-item active"
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  </div>
            </div>
          </div>
        </div>
        
      </main>
    );
  }

};

export default Employees;
