import React, { useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../utils/data";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";

const AddSupplierPurchase = () => {
  const {authToken, subscriptionDetails} = useAuthenticate()
  const [suppliers, setSuppliers] = useState([])
  const [addingPurchase, setAddingPurchase] = useState(false);
  const [purchaseData, setPurchaseData] = useState({
    sub_total: 0.00,
    discount: 0.00,
    gross_total: 0.00,
    amount_paid: 0.00,
    outstanding_balance: 0.00,
    date: ""
  });
  const [grossTotal, setGrossTotal] = useState(0.00)
  const [outstandingBalance, setOutstandingBalance] = useState(0.00)
  
  const [purchaseItemFormFields, setPurchaseItemFormFields] = useState([
    {
      item_name: "",
      quantity: "",
      unit_cost: "",
      total_cost: "",
    },
  ]);

  const addPurchaseItemFormField = () => {
    const object = {
      item_name: "",
      quantity: 0,
      unit_cost: 0.00,
      total_cost: "",
    };
    setPurchaseItemFormFields([...purchaseItemFormFields, object]);
  };

  const removePurchaseItemFormField = (index) => {
    const formFieldData = [...purchaseItemFormFields];
    formFieldData.splice(index, 1);
    setPurchaseItemFormFields(formFieldData);

    let subtotal = 0
    for(let i = 0; i< formFieldData.length; i++){
        subtotal += formFieldData[i]['total_cost']
    }

    setPurchaseData({
      ...purchaseData, ['sub_total']:subtotal,
    });

    calculateGrossTotal(subtotal, purchaseData?.discount);
  };

  const handlePurchaseItemChange = (e, index)=>{
    const formData = [...purchaseItemFormFields]
    formData[index][e.target.name] = e.target.value;
    setPurchaseItemFormFields(formData);

    
  }

  const calculateTotalCost = (quantity, index, unit_cost) => {
    let total_cost = quantity * unit_cost;
    const formData = [...purchaseItemFormFields]
    formData[index]['total_cost'] = total_cost;
    setPurchaseItemFormFields(formData);

    let subtotal = 0
    for(let i = 0; i< formData.length; i++){
        subtotal += formData[i]['total_cost']
    }
    
    calculateGrossTotal(subtotal, purchaseData?.discount);
    setPurchaseData({
      ...purchaseData, ['sub_total']:subtotal
    });

    
  }

  const calculateGrossTotal = (subtotal, discount) => {
    const discount_price = (parseFloat(discount) / 100) * subtotal;
    const gross_total = subtotal - discount_price
    setGrossTotal(gross_total)
  }

  const calculateOutstandingBalance = (gross_total, amount_paid) => {
    if(parseFloat(amount_paid) > parseFloat(gross_total)) {
      return toast.error("Amount Paid should be less than gross total");
    }
    setPurchaseData({
    ...purchaseData,['amount_paid']: amount_paid})
    const outstanding_balance = gross_total - parseFloat(amount_paid)
    console.log(outstanding_balance)
    setOutstandingBalance(outstanding_balance);
    
  }

  const handleFormChange = (e) => {
    setPurchaseData({
      ...purchaseData,
      [e.target.name]: e.target.value,
    });
  };

  const getSuppliersList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios
        .get(`${api_url}/supplier-management/`, config)
        .then((response) => {
          setSuppliers(response.data);
        });
    } catch (error) {
      toast.error("An error occured when getting suppliers list");
    }
  };


  const makePurchase = async (e) => {
    e.preventDefault();
    setAddingPurchase(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };


    let purchase_info = {
      ...purchaseData,
      "purchase_items":purchaseItemFormFields
    }
    purchase_info['gross_total'] = grossTotal;
    purchase_info['outstanding_balance'] = outstandingBalance;
    const body = JSON.stringify(purchase_info);

    await axios.post(`${api_url}/supplier-management/purchase/`, body, config).then(response=>{
      if(response.status === 201){
        Swal.fire({
          title: "Recorded!",
          text: "Supplier Purchase Recorded successfully",
          icon: "success",
        }).then(() => {
          
          setAddingPurchase(false)
          window.location.href = "/supplier-management/purchases"
        });
      }
    }).catch((error) => {
      console.error(error)
      setAddingPurchase(false)
      toast.error("An error occurred")
    });
  }

  useEffect(()=>{
    if(subscriptionDetails?.success){
      if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
        window.location.href = "/upgrade-subscription"
      }else{
        getSuppliersList();
      }
    }else{
      window.location.href="/no-subscription"
    }
    
  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Add New Purchase </span>
          </h1>
        </div>
        <form onSubmit={(e)=>makePurchase(e)} style={{ textAlign: "left" }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>Items Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label
                      className="input-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Supplier<span className="input-label-secondary">*</span>
                    </label>
                    <select
                      name="supplier"
                      className="form-control js-select2-custom"
                      onChange={(e) => handleFormChange(e)}
                      required
                    >
                      <option value="">---Select---</option>
                      {
                        suppliers?.map((supplier, index)=>(
                          <option value={supplier.id} key={index}>
                            {supplier?.company_name}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              {purchaseItemFormFields?.map((formField, index) => (
                <div className="row">
                  <div className="col-md-3">
                    <label className="form-label">Item Name</label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      name="item_name"
                      className="form-control"
                      id="name"
                      placeholder="Ex : Paracetamol"
                      onChange={(e) => handlePurchaseItemChange(e,index)}
                      required
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Quantity</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      name="quantity"
                      className="form-control"
                      id="name"
                      value={purchaseItemFormFields[index]['quantity']}
                      onChange={(e) => {
                        handlePurchaseItemChange(e,index);
                        calculateTotalCost(e.target.value, index, purchaseItemFormFields[index]['unit_cost'])
                    }}
                      required
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Unit Cost</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      step="0.01"
                      name="unit_cost"
                      className="form-control"
                      id="name"
                      value={purchaseItemFormFields[index]['unit_cost']}
                      onChange={(e) => {
                        handlePurchaseItemChange(e,index);
                        calculateTotalCost(purchaseItemFormFields[index]['quantity'], index, e.target.value)
                    }}
                      required
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Total Cost</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      step="0.01"
                      name="total_cost"
                      className="form-control"
                      id="name"
                      disabled
                      required
                      value={purchaseItemFormFields[index]['total_cost']}
                    />
                  </div>
                  <div className="col-md-1">
                    <label className="form-label mb-4"></label>
                    <div className="btn--container justify-content-center">
                      <Link
                        onClick={() => addPurchaseItemFormField()}
                        className="action-btn"
                        title="Add More"
                      >
                        <IoMdAdd />
                      </Link>
                      {index > 0 && (
                        <Link
                          onClick={() => removePurchaseItemFormField(index)}
                          className="action-btn btn--danger btn-outline-danger"
                        >
                          <MdDeleteOutline />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>Cost Details</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-4">
                  <label className="form-label">Sub Total</label>
                  <span className="text-danger">*</span>
                  <input
                    type="number"
                    step="0.01"
                    name="item_name"
                    className="form-control"
                    id="name"
                    onChange={(e) => handleFormChange(e)}
                    required
                    disabled
                    value={purchaseData?.sub_total}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Discount (in percentage)</label>
                  <span className="text-danger">*</span>
                  <input
                    type="number"
                    step="0.01"
                    name="discount"
                    className="form-control"
                    id="name"
                    onChange={(e) => {handleFormChange(e); calculateGrossTotal(purchaseData?.sub_total, e.target.value)}}
                    required
                    value={purchaseData?.discount}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Gross Total</label>
                  <span className="text-danger">*</span>
                  <input
                    type="number"
                    step="0.01"
                    name="unit_cost"
                    className="form-control"
                    id="name"
                    onChange={(e) => handleFormChange(e)}
                    required
                    disabled
                    value={grossTotal}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Amount Paid</label>
                  <span className="text-danger">*</span>
                  <input
                    type="number"
                    step="0.01"
                    name="amount_paid"
                    className="form-control"
                    id="name"
                    onChange={(e) => {
                      calculateOutstandingBalance(grossTotal, e.target.value)
                    }
                  }
                    required
                    value={purchaseData?.amount_paid}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Outstanding Balance</label>
                  <span className="text-danger">*</span>
                  <input
                    type="number"
                    step="0.01"
                    name="total_cost"
                    className="form-control"
                    id="name"
                    onChange={(e) => handleFormChange(e)}
                    disabled
                    required
                    value={outstandingBalance}
                  />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Date</label>
                  <span className="text-danger">*</span>
                  <input
                    type="date"
                    name="date"
                    className="form-control"
                    id="name"
                    onChange={(e) => handleFormChange(e)}
                    required
                    value={purchaseData?.date}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="btn--container justify-content-end mt-3">
            {!addingPurchase ? (
              <>
                <button type="reset" className="btn btn--reset">
                  Reset
                </button>
                <button type="submit" className="btn btn--primary">
                  Submit
                </button>
              </>
            ) : (
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="blue"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddSupplierPurchase;
