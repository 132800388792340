import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthenticate } from '../../../context/AuthContext'
import axios from 'axios'
import { api_url } from '../../../utils/data'
import { toast } from 'react-toastify'
import Loading from '../../../components/Loading/Loading'
import NoData from '../../../components/NoData'
import ReactPaginate from 'react-paginate'
import { MdDeleteOutline, MdOutlineRemoveRedEye } from 'react-icons/md'
import Swal from 'sweetalert2'
import { RotatingLines } from 'react-loader-spinner'

const PharmaciesList = () => {
  const {authToken} = useAuthenticate()
  const [loadingPharmacies, setLoadingPharmacies] = useState(true)
  const [pharmacies, setPharmacies] = useState([])
  const [pharmacyList, setPharmacyList] = useState([])
  const [deletingPharmacy, setDeletingPharmacy]  = useState(false)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % pharmacies.length;
    setItemOffset(newOffset);
  };
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = pharmacies.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(pharmacies.length / itemsPerPage);



  const getPharmacies = async () => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken
      }
    }
    await axios.get(`${api_url}/accounts/pharmacy/`,config).then((response)=>{
      
      setPharmacies(response.data);
      setPharmacyList(response.data)
      setLoadingPharmacies(false);
    }).catch((error)=>{
      setLoadingPharmacies(false)
      if(error?.response?.status === 500){
        return toast.error("Internal Server Error")
      }
    })
  }

  const filterPharamcyList = (search) => {
    
    if (search === "") {
      setPharmacies(pharmacyList);
    } else {
      const filteredPharmaciesList = pharmacyList.filter((pharmacy) => {
        return pharmacy?.pharmacy_name?.toLowerCase().includes(search.toLowerCase()) 
      });
      setPharmacies(filteredPharmaciesList);
    }
  };


  const deletePharmacy = (pharmacy_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingPharmacy(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        axios
          .delete(`${api_url}/accounts/pharmacy/${pharmacy_id}/`, config)
          .then(() => {
            setDeletingPharmacy(false)
            Swal.fire({
              title: "Deleted!",
              text: "Pharmacy has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          }).catch((error)=>{
            setDeletingPharmacy(false)
          });
      }
    });
  };

  useEffect(()=>{
    getPharmacies()
  },[])

  if(loadingPharmacies){
    return <Loading />
  }else{
    return (
      <main id="content" role="main" className="main main-page pointer-event">
        <div className="content container-fluid">
          <div className="page-header">
            <h1 className="mb-0 page-header-title">
              <span className="">Pharmacies</span>
            </h1>
          </div>
          <div className="card">
            <div className="card-body p-5px">
              <div className="order-top">
                <div className="card--header">
                  <form
                    
                  >
                    <div className="input-group">
                      <input
                        id="datatableSearch_"
                        type="search"
                        name="search"
                        className="form-control"
                        placeholder="Search by Pharmacy Name"
                        aria-label="Search"
                        required
                        
                        onChange={(e) => filterPharamcyList(e.target.value)}
                      />
                    </div>
                  </form>
  
                  <div className="hs-unfold mr-2">
                      <Link to="/super-admin/pharmacies/new" className="btn btn-secondary">Register New Pharmacy</Link>                  
                  </div>
  
                </div>
              </div>
              <div className="table-responsive datatable-custom">
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="">Pharmacy ID</th>
                      <th>Pharmacy Name</th>
                      <th>Number of staff</th>
                      <th>Subscription Package</th>
                      <th>Date registered</th>
  
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="set-rows">
                    {
                      currentItems?.map((pharmacy, index)=>(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{pharmacy.pharmacy_id}</td>
                          <td>{pharmacy?.pharmacy_name}</td>
                          <td>{pharmacy?.no_of_staff}</td>
                          <td>{pharmacy?.subscription_package ? (
                            <>
                              {pharmacy?.subscription_package?.package}
                            </> 
                          ) : "Not Subscribed" }
                          </td>
                          <td>{pharmacy?.date_registered}</td>
                          <td>
                            {
                              deletingPharmacy ? (
                                <div className="d-flex align-items-center justify-content-center col-sm-12">
                                <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  color="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                              ):(
                                <div className="btn--container justify-content-center">
                                  <Link
                                    className="action-btn btn--primary btn-outline-primary"
                                    to={`/super-admin/pharmacy/${pharmacy?.id}/details`}
                                  >
                                    <MdOutlineRemoveRedEye />
                                  </Link>
                                  <Link className="action-btn btn--danger btn-outline-danger">
                                    <MdDeleteOutline
                                      onClick={() => deletePharmacy(pharmacy.id)}
                                    />
                                  </Link>
                                </div>

                              )
                            }
                          </td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </table>
                {pharmacies.length <= 0 && (
                    <NoData />
                  )}
              </div>
              
            </div>
            <div className="card-footer border-0">
              <div className="d-flex justify-content-center justify-content-sm-end">
                
                <nav>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                  
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }

}

export default PharmaciesList