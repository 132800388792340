import { AiFillHome } from "react-icons/ai";
import { MdOutlineLocalPharmacy } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { GoPackage } from "react-icons/go";
import { FaCartPlus } from "react-icons/fa";
import { IoPeopleOutline } from "react-icons/io5";
import { ImStatsBars } from "react-icons/im";
import { IoIosPeople } from "react-icons/io";
import { GiMedicines } from "react-icons/gi";
import { AiFillMedicineBox } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { RiCoupon4Line } from "react-icons/ri";


export const superadminMenuItems = [
    {
        path: "/super-admin/dashboard",
        name: "Dashboard",
        icon: <AiFillHome />,
    },
    {
        path: "/super-admin/pharmacies/list",
        name: "Pharmacies",
        icon: <MdOutlineLocalPharmacy />,
    },
    {
      path: "",
      name: "Subscription",
      icon: <TbReportAnalytics />,
      submenus: [
        {
          path: "/super-admin/subscription-report",
          name: "Subscription Report",
        },
        {
          path: "/super-admin/expired-subscriptions",
          name: "Expired Subscriptions",
        },
        {
          path: "/super-admin/new-subscription",
          name: "Subscribe Pharmacy",
        },
      ],
    },
    {
        path:"/super-admin/packages/list",
        name:"Packages",
        icon: <GoPackage  />
    },
    {
      path:"/super-admin/coupons/list",
      name:"Coupons",
      icon: <RiCoupon4Line  />
  }
]

export const menuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Inventory Management",
      icon: <GiMedicines />,
      submenus: [
        {
        path: "/medicine/categories",
        name: "Medicine Categories",
        },
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
          path: "/medicine/add",
          name: "Add Medicine",
        },
        // {
        //     path:"/academics/academic-terms",
        //     name: "Import Medicine"
        // },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/staff-management/*",
      name: "Staff Management",
      icon: <IoPeopleOutline />,
      submenus: [
        {
          path: "/staff-management/employees",
          name: "Employees",
        },
        {
          path: "/staff-management/attendance-details",
          name: "Attendance Report",
        },
        {
          path: "/staff-management/staff-attendance-report",
          name: "Your Attendance Report",
        },
      ],
    },
    {
      path: "/reports/*",
      name: "Report And Analytics",
      icon: <ImStatsBars />,
      submenus: [
        {
          path: "/reports/revenue",
          name: "Revenue Report",
        },
        {
          path: "/reports/expense",
          name: "Expense Report",
        },
        {
          path: "/reports/income-statement",
          name: "Income Statement",
        },
      ],
    },
    {
      path: "/customers/*",
      name: "Customers",
      icon: <IoIosPeople />,
      submenus: [
        {
          path: "/customers/list",
          name: "Customer List",
        },
        {
          path: "/customers/debts",
          name: "Customer Debts",
        },
        {
          path: "/customers/new",
          name: "Add New Customer",
        },
      ],
    },
    {
      path: "/supplier-management/*",
      name: "Supplier Management",
      icon: <AiFillMedicineBox />,
      submenus: [
        {
          path: "/suppliers/list",
          name: "Suppliers",
        },
        {
          path: "/supplier-management/purchases",
          name: "Supplier Purchases",
        },
      ],
    },
    {
      path: "/settings/*",
      name: "Settings",
      icon: <IoSettingsOutline />,
      submenus: [
        {
          path: "/pharmacy-details",
          name: "Pharmacy Details",
        },
        {
          path: "/system-settings",
          name: "System Settings",
        },
        {
          path: "/subscription",
          name: "Subscription",
        },
      ],
    },
  ];


  export const employeeMenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Inventory Management",
      icon: <GiMedicines />,
      submenus: [
        {
        path: "/medicine/categories",
        name: "Medicine Categories",
        },
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
          path: "/medicine/add",
          name: "Add Medicine",
        },
        // {
        //     path:"/academics/academic-terms",
        //     name: "Import Medicine"
        // },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/staff-management/*",
      name: "Staff Management",
      icon: <IoPeopleOutline />,
      submenus: [
        {
          path: "/staff-management/staff-attendance-report",
          name: "Your Attendance Report",
        },
      ],
    },
    
    {
      path: "/customers/*",
      name: "Customers",
      icon: <IoIosPeople />,
      submenus: [
        {
          path: "/customers/list",
          name: "Customer List",
        },
        {
          path: "/customers/debts",
          name: "Customer Debts",
        },
        {
          path: "/customers/new",
          name: "Add New Customer",
        },
      ],
    },
    {
      path: "/supplier-management/*",
      name: "Supplier Management",
      icon: <AiFillMedicineBox />,
      submenus: [
        {
          path: "/suppliers/list",
          name: "Suppliers",
        },
        {
          path: "/supplier-management/purchases",
          name: "Supplier Purchases",
        },
      ],
    },
  ];


// ALPHA LINKS
  export const alphaMenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Inventory Management",
      icon: <GiMedicines />,
      submenus: [
        {
        path: "/medicine/categories",
        name: "Medicine Categories",
        },
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
          path: "/medicine/add",
          name: "Add Medicine",
        },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/staff-management/*",
      name: "Staff Management",
      icon: <IoPeopleOutline />,
      submenus: [
        {
          path: "/staff-management/employees",
          name: "Employees",
        },
      ],
    },
    
    {
      path: "/settings/*",
      name: "Settings",
      icon: <IoSettingsOutline />,
      submenus: [
        {
          path: "/pharmacy-details",
          name: "Pharmacy Details",
        },
        {
          path: "/subscription",
          name: "Subscription",
        },
      ],
    },
  ];

  export const alphaEmployeeMenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Inventory Management",
      icon: <GiMedicines />,
      submenus: [
        {
        path: "/medicine/categories",
        name: "Medicine Categories",
        },
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
          path: "/medicine/add",
          name: "Add Medicine",
        },
        // {
        //     path:"/academics/academic-terms",
        //     name: "Import Medicine"
        // },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/customers/*",
      name: "Customers",
      icon: <IoIosPeople />,
      submenus: [
        {
          path: "/customers/list",
          name: "Customer List",
        },
        {
          path: "/customers/debts",
          name: "Customer Debts",
        },
        {
          path: "/customers/new",
          name: "Add New Customer",
        },
      ],
    },
  ];

// END ALPHA LINKS


// COMPACT LINKS
export const compactMenuItems = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  {
    path: "/medicine/*",
    name: "Inventory Management",
    icon: <GiMedicines />,
    submenus: [
      {
      path: "/medicine/categories",
      name: "Medicine Categories",
      },
      {
        path: "/medicine/list",
        name: "Medicine List",
      },
      {
        path: "/medicine/add",
        name: "Add Medicine",
      },
    ],
  },
  {
    path: "/sales/*",
    name: "Sales",
    icon: <FaCartPlus />,
    submenus: [
      {
        path: "/sales/new",
        name: "New Sale",
      },
      {
        path: "/sales/list",
        name: "Sales History",
      },
    ],
  },
  {
    path: "/staff-management/*",
    name: "Staff Management",
    icon: <IoPeopleOutline />,
    submenus: [
      {
        path: "/staff-management/employees",
        name: "Employees",
      },
      {
        path: "/staff-management/attendance-details",
        name: "Attendance Report",
      },
      {
        path: "/staff-management/staff-attendance-report",
        name: "Your Attendance Report",
      },
    ],
  },
  {
    path: "/reports/*",
    name: "Report And Analytics",
    icon: <ImStatsBars />,
    submenus: [
      {
        path: "/reports/revenue",
        name: "Revenue Report",
      },
      {
        path: "/reports/expense",
        name: "Expense Report",
      },
    ],
  },
  {
    path: "/customers/*",
    name: "Customers",
    icon: <IoIosPeople />,
    submenus: [
      {
        path: "/customers/list",
        name: "Customer List",
      },
      {
        path: "/customers/debts",
        name: "Customer Debts",
      },
      {
        path: "/customers/new",
        name: "Add New Customer",
      },
    ],
  },
  {
    path: "/settings/*",
    name: "Settings",
    icon: <IoSettingsOutline />,
    submenus: [
      {
        path: "/pharmacy-details",
        name: "Pharmacy Details",
      },
      {
        path: "/system-settings",
        name: "System Settings",
      },
      {
        path: "/subscription",
        name: "Subscription",
      },
    ],
  },
];


export const compactEmployeeMenuItems = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  {
    path: "/medicine/*",
    name: "Inventory Management",
    icon: <GiMedicines />,
    submenus: [
      {
      path: "/medicine/categories",
      name: "Medicine Categories",
      },
      {
        path: "/medicine/list",
        name: "Medicine List",
      },
      {
        path: "/medicine/add",
        name: "Add Medicine",
      },
      // {
      //     path:"/academics/academic-terms",
      //     name: "Import Medicine"
      // },
    ],
  },
  {
    path: "/sales/*",
    name: "Sales",
    icon: <FaCartPlus />,
    submenus: [
      {
        path: "/sales/new",
        name: "New Sale",
      },
      {
        path: "/sales/list",
        name: "Sales History",
      },
    ],
  },
  {
    path: "/staff-management/*",
    name: "Staff Management",
    icon: <IoPeopleOutline />,
    submenus: [
      {
        path: "/staff-management/staff-attendance-report",
        name: "Your Attendance Report",
      },
    ],
  },
  
  {
    path: "/customers/*",
    name: "Customers",
    icon: <IoIosPeople />,
    submenus: [
      {
        path: "/customers/list",
        name: "Customer List",
      },
      {
        path: "/customers/debts",
        name: "Customer Debts",
      },
      {
        path: "/customers/new",
        name: "Add New Customer",
      },
    ],
  },
];