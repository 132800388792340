import React from 'react'
import NotFoundImage from '../../assets/404.jpg'

const UpgradeSubscription = () => {
  return (
    <main className='main main-page d-flex flex-column justify-content-center align-items-center'>
        <img src={NotFoundImage} alt="" style={{height:'500px'}} />
        <div>
            <h3 style={{fontSize:"70px", fontWeight:"800", textAlign:"center"}}>
                Unauthorized
            </h3>
            <p>
                Unfortunately, your subscription does not allow you to have this feature. Contact Us to upgrade your subscription
            </p>
        </div>
    </main>
  )
}

export default UpgradeSubscription