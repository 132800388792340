import React from 'react'
import './NoSubscription.css'
import NotFoundImage from "../../assets/expired_subscription.svg"
import { useAuthenticate } from '../../context/AuthContext'
import { Link } from 'react-router-dom'

const NoSubscription = () => {
    const {subscriptionDetails} = useAuthenticate()
    console.log(subscriptionDetails)
  return (
    <div className="main main-page pointer-event" id='no-subscription'>
      <div className='sub_err'>
        <img src={NotFoundImage} alt="" />
        <div className='sub_err-details'>
          <div className="sub_err-type">
            {subscriptionDetails?.error_type}
          </div>
          <h3 className="sub_err-title">{subscriptionDetails?.error_title}</h3>
          <p className="sub_err-desc">
            {subscriptionDetails?.message}
          </p>
        </div>
      </div>

      <div className="current-plan">
        <div className="current_plan-header">
          {subscriptionDetails?.subscription?.package}
        </div>
        <div className="current_plan-details">
          <div className='current_plan-price'>
            <h3>GHS {subscriptionDetails?.subscription?.package_cost}  <span>/month</span></h3>
          </div>
          <Link to="/subscription-packages">
            <button className=" plans-btn">
              View Plans
            </button>
          </Link>
        </div>
        <div className="current_plan-footer">
          <div>
            <p>
              <b>Date Registered:</b> {subscriptionDetails?.subscription?.registration_date}
            </p>
            <p>
              <b>Expiry Date:</b> {subscriptionDetails?.subscription?.expiry_date}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoSubscription