import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api_url } from "../../utils/data";
import axios from "axios";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";

const SubscriptionDetails = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();

  useEffect(() => {
    if(!subscriptionDetails?.success){
      window.location.href = "/no-subscription"
    }
    
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">SUBSCRIPTION DETAILS</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header">
            <h4>Current Plan</h4>
          </div>
          {subscriptionDetails?.success ? (
            <div className="card-body">
              <h2>{subscriptionDetails?.subscription?.package}</h2>
              <p>
                {
                    subscriptionDetails?.subscription?.duration
                }days
              </p>

              <p>
                Subscription Date: <strong>{subscriptionDetails?.subscription?.registration_date}</strong>
              </p>
              <p>
                Expiry Date: <strong>{subscriptionDetails?.subscription?.expiry_date}</strong>
              </p>
              {/* <Link to="/subscription-packages">
                <button className="btn btn-outline-primary d-block mb-3 py-2 px-10">
                  Upgrade Subscription
                </button>
              </Link> */}
            </div>
          ) : (
            <div className="card-body">
              <h2>You are not subscribed to any package.</h2>
              <Link>
                <button className="btn btn-outline-primary d-block mb-3 py-2 px-10">
                  Subscribe
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default SubscriptionDetails;
