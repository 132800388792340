import React, { useState } from "react";
import { useAuthenticate } from "../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../utils/data";
import Swal from "sweetalert2";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";

const SubscriptionPackages = () => {
  const {packages, pharmacyDetails, authToken} = useAuthenticate()
  const [subscribing, setSubscribing] = useState(false)

  const subscribe = async (package_id,package_type) => {
    if(package_type === "Paid"){
      return toast.error("Sorry, this package is not available now")
    }

    setSubscribing(true)
    const body = JSON.stringify({
      "package": package_id,
      'pharmacy':pharmacyDetails?.id,
      "amount_paid":0.00
    })
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization:"Bearer " + authToken
      }
    }

    await axios.post(`${api_url}/subscription/`, body, config).then((response)=>{
      console.log(response);
      Swal.fire({
        title:"Created!",
        text:"Subscription Successful",
        icon:"success"
      }).then(()=>{
        window.location.href = "/subscription"
      })
      setSubscribing(false)
    }).catch((error)=>{
      console.log(error);
      setSubscribing(false);
    })
  }

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">SUBSCRIPTION PACKAGES</span>
          </h1>
        </div>
        {
          subscribing ? <Loading /> : (
            <div>
              <div className="d-flex gap-1 align-items-center" style={{flexDirection:"column"}}>
                <small style={{color:"rgb(0, 102, 245)", letterSpacing:"3px", fontWeight:"600"}}>PRICING</small>
                <h3 style={{fontSize:"20px", fontWeight:'700', marginBottom:"0px"}}>Pricing plans for all use cases</h3>
                <p>Select the plan that best serves your need and plan to get the best of value</p>
              </div>
              <div className="row">
                {
                  packages?.map((packageItem, index) => (
                    <div className="col-lg-3 col-md-6" key={index}>
                      <div className="card">
                        <div className="card-body">
                          <h1 className="font-bold">{packageItem?.name}</h1>
                          <h6 class="card-subtitle mb-2 text-muted">
                            {packageItem?.description}
                          </h6>
                          <div className="mt-5">
                            <h3 className="font-bold">
                              <span>GHS {packageItem?.price}</span> <small>for {packageItem?.duration} days</small>
                            </h3>
                          </div>
                          <Link to={`/purchase-subscription/${packageItem?.id}/`} className="btn btn-primary d-block mb-3 p-2 w-100">
                            Get Started
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
    </main>
  );
};

export default SubscriptionPackages;
