import React from 'react'
import NotFoundImage from "../assets/404.jpg"

const NotFound = () => {
  return (
    <main className='main main-page d-flex flex-column justify-content-center align-items-center'>
        <img src={NotFoundImage} alt="" style={{height:'500px'}} />
        <div>
            <h3 style={{fontSize:"70px", fontWeight:"800", textAlign:"center"}}>
                Page Not Found
            </h3>
        </div>
    </main>
  )
}

export default NotFound