import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import ReactToPrint from "react-to-print";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import Swal from "sweetalert2";

const IncomeStatement = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [loadingIncomeStatement, setLoadingIncomeStatement] = useState(false);
  const [incomeStatement, setIncomeStatement] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const incomeStatementRef = useRef();

  const getIncomeStatement = async (e) => {
    e.preventDefault();
    setLoadingIncomeStatement(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios
      .get(
        `${api_url}/finance/income-statement/${filterStartDate}/${filterEndDate}`,
        config
      )
      .then((response) => {
        setIncomeStatement(response.data);
        setLoadingIncomeStatement(false);
      })
      .catch((error) => {
        
        setLoadingIncomeStatement(false);
      });
  };

  

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                className="w--20"
                alt=""
              />
            </span>
            <span className="">Income Statement</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header shadow flex-wrap p-20px border-0">
            <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
            <form
              onSubmit={(e) => getIncomeStatement(e)}
              className="w-100 mt-5"
            >
              <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="start_date">
                      Start Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        required
                        // value={filterStartDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterStartDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="end_date">
                      End Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        // value={filterEndDate}
                        required
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-12 col-lg-4 __btn-row">
                  <input
                    type="reset"
                    value="Clear"
                    id=""
                    className="btn w-100 btn--reset min-h-45px"
                    // onClick={() => clearData()}
                  />

                  <button
                    type="submit"
                    id="show_filter_data"
                    className="btn w-100 btn--primary min-h-45px"
                  >
                    Show data
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary">Print List</button>
                  )}
                  content={() => incomeStatementRef.current}
                />
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              {loadingIncomeStatement ? (
                <div className="text-center" style={{ marginTop: "40px" }}>
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <table
                  className="table table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                  ref={incomeStatementRef}
                >
                  <thead className="thead-light">
                    <tr>
                      <th></th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="font-bold">Income</td>
                      <td className="font-bold">GHS</td>
                    </tr>
                    {incomeStatement?.incomes?.map((income, index) => (
                      <tr key={index}>
                        <td>{income.income_type}</td>
                        <td>{income.amount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="font-bold">Total Income</td>
                      <td className="font-bold">
                        {incomeStatement?.total_income}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Expenses</td>
                      <td className="font-bold">GHS</td>
                    </tr>
                    {incomeStatement?.expenses?.map((expense, index) => (
                      <tr key={index}>
                        <td>{expense.expense_type}</td>
                        <td>{expense.amount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="font-bold">Total Expenses</td>
                      <td className="font-bold">
                        {incomeStatement?.total_expenses}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold">Net Income</td>
                      <td className="font-bold">
                        {incomeStatement?.net_income}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          {/* <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <nav>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="›"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="‹"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="page-item active"
                  pageLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  );
};

export default IncomeStatement;
