import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../../context/AuthContext";
import { api_url } from "../../../utils/data";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";

const NewSubscription = () => {
  const { packages, authToken } = useAuthenticate();
  const [creatingSubscription, setCreatingSubscription] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [subscriptionData, setSubscriptionData] = useState({});
  const [pharmacies, setPharmacies] = useState([])

  const handleSubscriptionFormChange = (e) => {
    setSubscriptionData({
      ...subscriptionData,
      [e.target.name]: e.target.value,
    });
  };

  const getSelectedPackage = (package_id) => {
    const filteredPackage = packages.filter((packageItem) => {
      return parseInt(package_id) === parseInt(packageItem.id);
    });
    setSelectedPackage(filteredPackage[0]);
  };

  const getPharmacies = async () => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken
      }
    }
    await axios.get(`${api_url}/accounts/pharmacy/`,config).then((response)=>{
      setPharmacies(response.data);
      
    }).catch((error)=>{
      
      if(error?.response?.status === 500){
        return toast.error("Internal Server Error")
      }else{
        return toast.error("Couldn't get pharmacies")
      }
    })
  }

  const subscribePharmacy = async (e) => {
    e.preventDefault()
    setCreatingSubscription(true)
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization:"Bearer " + authToken
      }
    }


    const body = JSON.stringify(subscriptionData);
    
    await axios.post(`${api_url}/subscription/`, body, config).then((response)=>{
      console.log(response);
      Swal.fire({
        title:"Created!",
        text:"Subscription Successful!",
        icon:"success"
      }).then(()=>{
        window.location.href = "/super-admin/pharmacies/list"
      })
      setCreatingSubscription(false)
    }).catch((error)=>{
      console.log(error);
      toast.error("An error occurred while creating subscription")
      setCreatingSubscription(false);
    })
  }

  useEffect(()=>{
    getPharmacies();
  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span> New Subscription </span>
          </h1>
        </div>
        <form onSubmit={(e)=>subscribePharmacy(e)} id="product_form" className="row g-2">
          <div className="col-lg-12">
            {/* SUBSCRIPTION PACKAGE */}
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-user" />
                  </span>
                  <span> Subscription Package </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Pharmacy<span className="input-label-secondary">*</span>
                      </label>
                      <select
                        name="pharmacy"
                        onChange={(e) => {
                          handleSubscriptionFormChange(e);                          
                        }}
                        className="form-control js-select2-custom"
                        required
                      >
                        <option value="">---Select---</option>
                        {pharmacies?.map((pharmacy, index) => (
                          <option key={index} value={pharmacy?.id}>
                            {pharmacy?.pharmacy_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Package<span className="input-label-secondary">*</span>
                      </label>
                      <select
                        name="package"
                        onChange={(e) => {
                          handleSubscriptionFormChange(e);
                          getSelectedPackage(e.target.value);
                        }}
                        className="form-control js-select2-custom"
                        required
                      >
                        <option value="">---Select---</option>
                        {packages?.map((package_item, index) => (
                          <option key={index} value={package_item?.id}>
                            {package_item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="input-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Package Cost
                          </label>
                          <input
                            type="text"
                            name="package_cost"
                            id="en_package_cost"
                            className="form-control"
                            value={selectedPackage?.price}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="input-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Duration (days)
                          </label>
                          <input
                            type="number"
                            name="duration"
                            id="en_duration"
                            className="form-control"
                            value={selectedPackage?.duration}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="form-group">
                          <label
                            className="input-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Discount Coupon
                          </label>
                          <input
                            type="text"
                            name="coupon"
                            id="en_coupon"
                            className="form-control"
                            onChange={(e) => handleSubscriptionFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                          <label
                            className="input-label"
                            style={{ color: "transparent" }}
                          >
                            a
                          </label>
                          <button className="form-control btn btn-success">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Amount to pay
                      </label>
                      <input
                        type="text"
                        name="package_cost"
                        id="en_package_cost"
                        className="form-control"
                        value={selectedPackage?.price}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Amount Paid
                      </label>
                      <input
                        type="text"
                        name="amount_paid"
                        id="en_amount_paid"
                        className="form-control"
                        required
                        onChange={(e) => handleSubscriptionFormChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="btn--container justify-content-end">
              {!creatingSubscription ? (
                <>
                  <a href="" className="btn btn--reset min-w-120px">
                    Reset
                  </a>
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                </>
              ) : (
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  strokeColor="blue"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default NewSubscription;
