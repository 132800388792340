import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SuperAdminDashboard from '../pages/Dashboard/SuperAdminDashboard'
import PharmaciesList from '../pages/SuperAdminPages/Pharmacies/PharmaciesList'
import NewPharmacy from '../pages/SuperAdminPages/Pharmacies/NewPharmacy'
import SubscriptionReport from '../pages/SuperAdminPages/Subscriptions/SubscriptionReport'
import PackagesList from '../pages/SuperAdminPages/Packages/PackagesList'
import NewSubscription from '../pages/SuperAdminPages/Subscriptions/NewSubscription'
import PharmacyDetails from '../pages/SuperAdminPages/Pharmacies/PharmacyDetails'
import Coupons from '../pages/SuperAdminPages/Coupons/Coupons'
import NewCoupon from '../pages/SuperAdminPages/Coupons/NewCoupon'

const SuperadminRoutes = () => {
  return (
    <Routes>
        <Route path='/dashboard' element={<SuperAdminDashboard />} />
        <Route path="/pharmacies/list" element={<PharmaciesList />} />
        <Route path="/pharmacies/new" element={<NewPharmacy />} />
        <Route path='/pharmacy/:pharmacy_id/details/' element={<PharmacyDetails />} />
        <Route path='/new-subscription' element={<NewSubscription />} />
        <Route path="/subscription-report" element={<SubscriptionReport />} />
        <Route path="/packages/list" element={<PackagesList />} />
        <Route path="/coupons/list" element={<Coupons />} />
        <Route path="/coupons/new" element={<NewCoupon />} />
    </Routes>
  )
}

export default SuperadminRoutes