import React, { useEffect, useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { api_url } from '../../utils/data'
import { useAuthenticate } from '../../context/AuthContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading/Loading'

const SupplierDetails = () => {
    const {supplier_id} = useParams()
    const {authToken, subscriptionDetails}=useAuthenticate()
    const [updateData, setUpdateData] = useState({})
    const [updatingSupplier, setUpdatingSupplier] = useState(false)
    const [supplier, setSupplier] = useState({})
    const [gettingSupplier, setGettingSupplier] = useState(true)


    const handleFormChange = (e) => {
        setUpdateData({
          ...updateData,
          [e.target.name]: e.target.value,
        });
      };

    const updateSupplier = async (e) => {
        e.preventDefault()
        setUpdatingSupplier(true)
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          };
          const body = JSON.stringify(updateData)
          try {
            await axios.put(`${api_url}/supplier-management/supplier/${supplier_id}/`,body, config).then((response)=>{
              if(response.status === 200){
                Swal.fire({
                  title: "Updated!",
                  text: "Supplier has been updated.",
                  icon: "success",
                }).then(()=>{
                  window.location.href = "/suppliers/list"
                })
                setUpdatingSupplier(false)
              }
            })
          } catch (error) {
            console.log(error);
            if(error?.response?.status === 500){
              return toast.error("Internal server error");
            }
            if(error?.response?.status === 400){
              for(const property in error.response.data){
                if(property !== "0"){
                  toast.error(`${property} error: ${error.response.data[property]}`)
                }else{
                  toast.error(`${error.response.data[property]}`)
                }
              }
            }else{
              toast.error("Something went wrong");
            }
            setUpdatingSupplier(false);
          }
      }

      const getSupplier = async () => {
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          };
          try {
            await axios.get(`${api_url}/supplier-management/supplier/${supplier_id}/`, config).then((response)=>{
              if(response.status === 200){
                setSupplier(response.data)
                setGettingSupplier(false)
              }
            })
          } catch (error) {
            console.log(error);
            if(error?.response?.status === 500){
              return toast.error("Internal server error");
            }
            if(error?.response?.status === 400){
              for(const property in error.response.data){
                if(property !== "0"){
                  toast.error(`${property} error: ${error.response.data[property]}`)
                }else{
                  toast.error(`${error.response.data[property]}`)
                }
              }
            }else{
              toast.error("Something went wrong");
            }
            setUpdatingSupplier(false);
          }
      }

      useEffect(()=>{
        if(subscriptionDetails?.success){
          if(subscriptionDetails?.subscription?.package !== "Premium" && subscriptionDetails?.subscription?.package !== "Free Trial"){
            window.location.href = "/upgrade-subscription"
          }else{
            getSupplier()
          }
        }else{
          window.location.href="/no-subscription"
        }
        

      },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Add New Customer </span>
          </h1>
        </div>
        {
            gettingSupplier ? (
                <Loading />
            ):(
                <form onSubmit={(e) => updateSupplier(e)} style={{ textAlign: "left" }}>
                
                <div className="card">
                    <div className="card-header">
                    <h5 className="card-title">
                        <span className="card-header-icon">
                        <i className="tio-user" />
                        </span>
                        <span>General Information</span>
                    </h5>
                    </div>
                    <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-12">
                        <div className="row g-3">
                            <div className="col-md-4">
                            <label className="form-label">Company Name</label>
                            <span className="text-danger">*</span>
                            <input
                                type="text"
                                name="company_name"
                                className="form-control"
                                id="name"
                                placeholder="Ex : John Doe"
                                onChange={(e) => handleFormChange(e)}
                                required
                                value={
                                    updateData?.company_name ? updateData.company_name : supplier?.company_name
                                }
                            />
                            </div>
                            <div className="col-md-4">
                            <label className="form-label">Contact Name</label>
                            <span className="text-danger">*</span>
                            <input
                                type="text"
                                name="contact_name"
                                className="form-control"
                                id="name"
                                placeholder="Ex : John Doe"
                                onChange={(e) => handleFormChange(e)}
                                required
                                value={
                                    updateData?.contact_name ? updateData.contact_name : supplier?.contact_name
                                }
                            />
                            </div>
                            <div className="col-md-4">
                            <label className="form-label">Phone</label>
                            <span className="text-danger">*</span>
                            <input
                                type="text"
                                name="phone"                        
                                className="form-control"
                                id="phone"
                                placeholder="Ex : +233********"
                                onChange={(e) => handleFormChange(e)}
                                required
                                value={
                                    updateData?.phone ? updateData.phone : supplier?.phone
                                }
                            />
                            </div>

                            <div className="col-md-4">
                            <label className="form-label">Address</label>
                            <input
                                type="text"
                                name="address"
                                defaultValue=""
                                className="form-control"
                                id="email"
                                placeholder="Ex : Plot 2 Kwadaso"
                                onChange={(e) => handleFormChange(e)}
                                value={
                                    updateData?.address ? updateData.address : supplier?.address
                                }
                            />
                            </div>

                            <div className="col-md-4">
                            <label className="form-label">Email</label>
                            <input
                                type="text"
                                name="email"
                                className="form-control"
                                id="email"
                                placeholder="Ex : example@example.com"
                                onChange={(e) => handleFormChange(e)}
                                value={
                                    updateData?.email ? updateData.email : supplier?.email
                                }
                            />
                            </div>
                            
                        </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                
                <div className="btn--container justify-content-end mt-3">
                    {!updatingSupplier ? (
                    <>
                        <button type="reset" className="btn btn--reset">
                        Reset
                        </button>
                        <button type="submit" className="btn btn--primary">
                        Submit
                        </button>
                    </>
                    ) : (
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeColor="blue"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    )}
                </div>
                </form>
            )
        }
      </div>
    </main>
  )
}

export default SupplierDetails