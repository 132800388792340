import React, {useEffect, useState} from 'react'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import Dashboard from '../pages/Dashboard/Dashboard'
import { Routes, Route} from "react-router-dom"
import MedicineStock from '../pages/Medicine/MedicineStock'
import AddDrug from '../pages/Medicine/AddDrug'
import Categories from '../pages/Medicine/Categories'
import NewSale from '../pages/Sales/NewSale'
import SalesHistory from '../pages/Sales/SalesHistory'
import SuperadminRoutes from '../Routes/SuperadminRoutes'
import SaleDetails from '../pages/Sales/SaleDetails'
import SaleReceipt from '../pages/Sales/SaleReceipt'
import PaymentReceipt from '../pages/Sales/PaymentReceipt'
import Employees from '../pages/StaffManagement/Employees'
import NewEmployee from '../pages/StaffManagement/NewEmployee'
import CategoryDetails from '../pages/Medicine/CategoryDetails'
import MedicineDetails from '../pages/Medicine/MedicineDetails'
import EmployeeDetails from '../pages/StaffManagement/EmployeeDetails'
import AttendanceReport from '../pages/StaffManagement/AttendanceReport'
import AttendanceDetails from '../pages/StaffManagement/AttendanceDetails'
import RevenueReport from '../pages/Reports/RevenueReport'
import RevenueDetails from '../pages/Reports/RevenueDetails'
import ExpenseReport from '../pages/Reports/ExpenseReport'
import IncomeStatement from '../pages/Reports/IncomeStatement'
import CustomerList from '../pages/Customers/CustomerList'
import CustomerSales from '../pages/Customers/CustomerSales'
import AddCustomer from '../pages/Customers/AddCustomer'
import CustomerDebts from '../pages/Customers/CustomerDebts'
import CustomerDetails from '../pages/Customers/CustomerDetails'
import Profile from '../pages/Settings/Profile'
import NotFound from '../pages/NotFound'
import Suppliers from '../pages/SupplierManagement/Suppliers'
import AddSupplier from '../pages/SupplierManagement/AddSupplier'
import SupplierDetails from '../pages/SupplierManagement/SupplierDetails'
import AddSupplierPurchase from '../pages/SupplierManagement/AddSupplierPurchase'
import SupplierPurchases from '../pages/SupplierManagement/SupplierPurchases'
import PurchaseDetails from '../pages/SupplierManagement/PurchaseDetails'
import PharmacyDetails from '../pages/Settings/PharmacyDetails'
import SystemSettings from '../pages/Settings/SystemSettings'
import SubscriptionPackages from '../pages/Settings/SubscriptionPackages'
import SubscriptionDetails from '../pages/Settings/SubscriptionDetails'
import SubscriptionPurchase from '../pages/Settings/SubscriptionPurchase'

import UpgradeSubscription from '../pages/Settings/UpgradeSubscription'
import NoSubscription from '../pages/Subscription/NoSubscription'

const Layout = () => {
  const [openSidebar, setOpenSidebar] = useState(true)
  return (
    <div className='footer-offset'>
        <Topbar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <Routes>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/medicine/list' element={<MedicineStock />} />
            <Route path="/medicine/:medicine_id/details" element={<MedicineDetails />} />
            <Route path="/medicine/add" element={<AddDrug />} />
            <Route path="/medicine/categories" element={<Categories />} />
            <Route path="/medicine/categories/:category_id" element={<CategoryDetails />} />
            

            <Route path='/sales/new' element={<NewSale />} />
            <Route path="/sales/list" element={<SalesHistory />} />
            <Route path="/sales/:sale_id/details" element={<SaleDetails />} />
            <Route path="/sales/:sale_id/receipt" element={<SaleReceipt />} />
            <Route path="/sales/payment/:payment_id/receipt" element={<PaymentReceipt />} />

            <Route path="/staff-management/employees" element={<Employees />} />
            <Route path="/staff-management/employees/new" element={<NewEmployee />} />
            <Route path="/staff-management/employees/:employee_id" element={<EmployeeDetails />} />


            <Route path="/staff-management/staff-attendance-report" element={<AttendanceReport />} />
            <Route path="/staff-management/attendance-details" element={<AttendanceDetails />} />


            <Route path="/reports/revenue" element={<RevenueReport />} />
            <Route path="/reports/revenue/:revenue_date" element={<RevenueDetails />} />
            <Route path="/reports/expense" element={<ExpenseReport />} />
            <Route path="/reports/income-statement" element={<IncomeStatement />} />

            <Route path="/customers/list" element={<CustomerList />} />
            <Route path="/customer-sales/:customer_id" element={<CustomerSales />} />
            <Route path="/customers/debts" element={<CustomerDebts />} />
            <Route path="/customers/new" element={<AddCustomer />} />
            <Route path="/customer/:customer_id/details" element={<CustomerDetails />} />

            <Route path="/settings/profile" element={<Profile />} />
            <Route path="/pharmacy-details" element={<PharmacyDetails />} />
            <Route path="/system-settings" element={<SystemSettings />} />
            <Route path="/subscription" element={<SubscriptionDetails />} />
            <Route path='/purchase-subscription/:package_id' element={<SubscriptionPurchase  />} />

            <Route path="/suppliers/list" element={<Suppliers />} />
            <Route path='/suppliers/new' element={<AddSupplier />} />
            <Route path="/supplier/:supplier_id" element={<SupplierDetails />} />
            <Route path="/supplier-management/purchases" element={<SupplierPurchases /> } />
            <Route path="/supplier-management/purchases/new" element={<AddSupplierPurchase /> } />
            <Route path="/supplier-management/purchases/:purchase_id/details" element={<PurchaseDetails />} />
            
            {/* SUBSCRIPTION */}
            <Route path="/no-subscription" element={<NoSubscription />} />
            
            

            {/* SUPER ADMIN ROUTES */}
            <Route path="/super-admin/*" element={<SuperadminRoutes />} />

            {/* SUBSCRITPION */}
            <Route path="/upgrade-subscription" element={<UpgradeSubscription />} />
            <Route path="/subscription-packages" element={<SubscriptionPackages />} />

            <Route path='/*' element={<NotFound />} />

        </Routes>
    </div>
  )
}

export default Layout