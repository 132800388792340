import React, { useState } from "react";
import "./Login.css";
import LoginImage from "../../assets/3836182.jpg";
import Logo from "../../assets/rxultimate.png";
import axios from "axios";
import { api_url } from "../../utils/data";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const Login = () => {
  const {user} = useAuthenticate()
  const [loggingIn, setLogginIn] = useState(false);
  const [loginData, setLoginData] = useState({});
  const handleFormChange = (e)=> {
    setLoginData({
      ...loginData, [e.target.name]:e.target.value
    })
  }

  const loginUser = (e) => {
    e.preventDefault();
    setLogginIn(true);
    const config = {
      headers: {
        "Content-Type":'application/json'
      }
    }
    const body = JSON.stringify(loginData);

    axios.post(`${api_url}/authentication/login/`, body, config).then((response)=>{
      localStorage.setItem('auth', JSON.stringify(response.data));


      const config = {
        headers: {
          "Content-Type":'application/json',
          Authorization:"Bearer " + response?.data['access']
        }
      } 
      axios.get(`${api_url}/accounts/me`,config).then((response)=>{
        console.log(response?.data)
        if(response?.data?.is_superuser){
          window.location.href="/super-admin/dashboard"
        }else if(response?.data?.is_pharmacy_admin){
          window.location.href="/dashboard"
        }else if(response?.data?.is_employee){
          window.location.href="/dashboard"
        }else{
          toast.error("Unauthorized access")
          localStorage.removeItem("auth");
        }
      }).catch((error)=>{
        console.log(error);
      });
      setLogginIn(false);


    }).catch((error)=>{
      console.log(error);
      setLogginIn(false);
      if(error?.response?.data){
        console.log(error.response.data)
        for(const property in error.response.data){
          if(property !== "detail"){
            toast.error(`${property}: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }
    })
  }

  if(user){
    if(user?.is_superuser){
      return <Navigate to="/super-admin/dashboard" />
    }else{
      return <Navigate to="/dashboard" />
    }
  }else{
    return (
      <div className="login-page">
        <div className="login-form-container">
          <div className="form-wrap">
            <form onSubmit={e=>loginUser(e)} className="login-form">
              <div className="form-header">
                <div className="logo">
                  <img src={Logo} alt="logo" />
                </div>
                <div className="heading">
                  <h3>Login</h3>
                  <h6>Enter your credentials to login</h6>
                </div>
              </div>
  
              <div className="form-body">
                <div className="js-form-message form-group">
                  <label
                    className="input-label text-capitalize"
                    htmlFor="signinSrEmail"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="username"
                    id="username"
                    onChange={(e)=>handleFormChange(e)}
                    aria-label="username"
                    required
                  />
                </div>
  
                <div className="js-form-message form-group">
                  <label
                    className="input-label text-capitalize"
                    htmlFor="signinSrEmail"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    name="password"
                    id="password"
                    onChange={(e)=>handleFormChange(e)}
                    aria-label="username"
                    required
                  />
                </div>
                {
                  loggingIn ? (
                    <div className="d-flex justify-content-center align-items-center">

                        <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    strokeColor="blue"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                    </div>
                  ):(
                    <button type="submit" className="btn btn-block btn--primary">
                        Login
                      </button>
  
                  )
                }
              </div>
            </form>
          </div>
  
          <div className="image-container">
            <div className="image-wrapper">
              <img src={LoginImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
