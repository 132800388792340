import React, {useEffect, useRef, useState} from 'react'
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import ReactPaginate from "react-paginate";
import NoData from "../../components/NoData"
import { useAuthenticate } from '../../context/AuthContext';
import axios from 'axios';
import { api_url } from '../../utils/data';
import Loading from '../../components/Loading/Loading';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const MedicineStock = () => {
    const {authToken, subscriptionDetails} = useAuthenticate()
    const medicineListRef = useRef();
    const [drugList, setDrugList] = useState([]);
    const [medicineList, setMedicineList] = useState([]);
    const [gettingMedicineList, setGettingMedicineList] = useState(true);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % medicineList.length;
    setItemOffset(newOffset);
  };
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = medicineList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(medicineList.length / itemsPerPage);


  const filterDrugList = (search) => {
    
    if (search === "") {
      setMedicineList(drugList);
    } else {
      const filteredMedicineList = medicineList.filter((medicine) => {
        return search.toLowerCase() === ""
          ? medicine
          : medicine.name.toLowerCase().includes(search) || medicine?.generic_name?.toLowerCase().includes(search)
              
      });
      setMedicineList(filteredMedicineList);
    }
  };

  const getMedicineList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    await axios.get(`${api_url}/inventory/medicines/`, config).then((response)=>{
      setDrugList(response.data);
      setMedicineList(response.data);
      setGettingMedicineList(false);
    }).catch((error) => {
      setGettingMedicineList(false);
      toast.error("There was an error getting your drug list")
    });
  }

  const deleteMedicine = (medicine_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        axios
          .delete(`${api_url}/inventory/medicines/${medicine_id}/`, config)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Medicine has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

    useEffect(()=>{
      
      getMedicineList();
    },[])

    return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                className="w--20"
                alt=""
              />
            </span>
            <span className="">Medicine List</span>
          </h1>
        </div>
        {
          gettingMedicineList ? <Loading /> : (
            <div className="card">
              <div className="card-body p-5px">
                <div className="order-top">
                  <div className="card--header">
                    <form
                      
                    >
                      <div className="input-group">
                        <input
                          id="datatableSearch_"
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search by Drug Name or Generic Name"
                          aria-label="Search"
                          required
                          // onChange={(e)=>setSearch(e.target.value)}
                          onChange={(e) => filterDrugList(e.target.value)}
                        />
                        {/* <div className="input-group-append">
                          <button type="submit" className="input-group-text">
                            Search
                          </button>
                        </div> */}
                      </div>
                    </form>

                    <div className="hs-unfold mr-2">
                      <ReactToPrint
                        trigger={() => (
                          <div className="btn btn-primary">Print List</div>
                        )}
                        content={() => medicineListRef.current}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive datatable-custom">
                  <table
                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                    style={{ width: "100%" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="">SL</th>
                        <th className="table-column-pl-0">Medicine ID</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>

                        <th>
                          <div className="text-center">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="set-rows">
                      {currentItems.map((medicine, index) => (
                        <tr className="status-delivered class-all">
                          <td className="">{index + 1}</td>
                          <td className="table-column-pl-0">
                            <Link href="#">{medicine?.medicine_id}</Link>
                          </td>
                          <td>{medicine?.name}</td>
                          <td>
                            <span>{medicine?.quantity}</span>
                          </td>
                          <td>
                            <div>
                              <Link
                                className="text-body text-capitalize font-medium"
                                to="#"
                              >
                                {medicine?.final_price}
                              </Link>
                            </div>
                          </td>

                          <td>
                            <div className="btn--container justify-content-center">
                              <Link
                                to={`/medicine/${medicine.id}/details`}
                                className="action-btn"
                                title="Edit"
                              >
                                <MdEdit />
                              </Link>
                              <Link className="action-btn btn--danger btn-outline-danger">
                                <MdDeleteOutline
                                  onClick={() => deleteMedicine(medicine.id)}
                                />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {medicineList.length <= 0 && (
                    <NoData />
                  )}
                </div>
                {/* TABLE TO PRINT */}
                <div style={{ display: "none" }}>
                  <table
                    ref={medicineListRef}
                    className="table table-hover table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
                    style={{ width: "100%", marginTop: "100px" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="">SL</th>
                        <th className="table-column-pl-0">Medicine ID</th>
                        <th>Name</th>
                        <th>Generic Name</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                      </tr>
                    </thead>
                    <tbody id="set-rows">
                      {medicineList.map((medicine, index) => (
                        <tr className="status-delivered class-all">
                          <td className="">{index + 1}</td>
                          <td className="table-column-pl-0">
                            {medicine.medicine_id}
                          </td>
                          <td>{medicine.name}</td>
                          <td>{medicine.generic_name}</td>
                          <td>
                            <span>{medicine.quantity}</span>
                          </td>
                          <td>
                            <div>{medicine.final_price}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer border-0">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  {/* PAGINATION */}
                  <nav>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="›"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="‹"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      activeClassName="page-item active"
                      pageLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                    
                  </nav>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </main>
  )
}

export default MedicineStock