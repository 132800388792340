import React, { useEffect, useState } from 'react'
import { useAuthenticate } from '../../../context/AuthContext'
import { toast } from 'react-toastify'
import Loading from '../../../components/Loading/Loading'
import { api_url } from '../../../utils/data'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import NoData from '../../../components/NoData'
import { Link } from 'react-router-dom'

const Coupons = () => {
    const {authToken} = useAuthenticate()
    const [loadingCoupons, setLoadingCoupons] = useState(true)
    const [coupons, setCoupons] = useState([])
    const [couponList, setCouponList] = useState([])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % coupons.length;
        setItemOffset(newOffset);
      };
      const itemsPerPage = 20;
      const [itemOffset, setItemOffset] = useState(0);
      const endOffset = itemOffset + itemsPerPage;
      const currentItems = coupons.slice(itemOffset, endOffset);
      const pageCount = Math.ceil(coupons.length / itemsPerPage);
    
    
    
    const getCoupons = async () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
        }
        await axios
            .get(`${api_url}/subscription/coupons/`, config)
            .then((response) => {
                if(response?.data?.success){
                    setCouponList(response.data?.payload)
                    setCoupons(response.data?.payload)
                }else{
                    if(response?.data?.message){
                        toast.error(response?.data?.message)
                    }
                }
                setLoadingCoupons(false)
            })
            .catch((error) => {
                setLoadingCoupons(false)
            })
    }

    useEffect(()=>{
        getCoupons()
    },[])

    if(loadingCoupons){
        return <Loading />
    }else{
        return (
          <main id="content" role="main" className="main main-page pointer-event">
            <div className="content container-fluid">
              <div className="page-header">
                <h1 className="mb-0 page-header-title">
                  <span className="">Coupons</span>
                </h1>
              </div>
              <div className="card">
                <div className="card-body p-5px">
                  <div className="order-top">
                    <div className="card--header">
                      {/* <form
                        
                      >
                        <div className="input-group">
                          <input
                            id="datatableSearch_"
                            type="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by Package Name"
                            aria-label="Search"
                            required
                            
                          />
                        </div>
                      </form> */}
      
                      <div className="hs-unfold mr-2">
                          <Link to="/super-admin/coupons/new" className="btn btn-secondary">Add New Coupon</Link>                  
                      </div>
      
                    </div>
                  </div>
                  <div className="table-responsive datatable-custom">
                    <table
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                      style={{ width: "100%" }}
                    >
                      <thead className="thead-light">
                        
                        <tr>
                          <th className="">SL</th>
                          <th className="table-column-pl-0">Name</th>
                          <th>Date Created</th>
                          <th>Expiry Date</th>
                        </tr>
                      </thead>
                      <tbody id="set-rows">
                        {
                          coupons?.map((coupon, index)=>(
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{coupon?.code}</td>
                              <td>{coupon?.created_at}</td>
                              <td>{coupon?.expiry_date}</td>
                            </tr>
                          ))
                        }
                        
                      </tbody>
                    </table>
                    {coupons.length <= 0 && (
                    <NoData />
                  )}
                  </div>
                  
                </div>
                <div className="card-footer border-0">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    
                    <nav>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="›"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="‹"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        activeClassName="page-item active"
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                      
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )
    }
}

export default Coupons