import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link } from "react-router-dom";
import { MdDeleteOutline, MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../context/AuthContext";
import { api_url } from "../../utils/data";
import NoData from "../../components/NoData";
import Swal from "sweetalert2";

const CustomerDebts = () => {
  const { authToken, subscriptionDetails } = useAuthenticate();
  const [customerDebtList, setCustomerDebtList] = useState([]);
  const [debtorsList, setDebtorsList] = useState([]);
  const [loadingDebtorsList, setLoadingDebtorsList] = useState(true);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = debtorsList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(debtorsList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % debtorsList.length;
    setItemOffset(newOffset);
  };

  const filterDebtorsList = (search) => {
    if (search === "") {
      setDebtorsList(customerDebtList);
    } else {
      const filteredDebtorsList = debtorsList.filter((debtor) => {
        return search.toLowerCase() === ""
          ? debtor
          : debtor.customer_name.toLowerCase().includes(search.toLowerCase());
      });
      setDebtorsList(filteredDebtorsList);
    }
  };

  const getDebtorsList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      await axios
        .get(`${api_url}/sales/debtors/`, config)
        .then((response) => {
          setCustomerDebtList(response.data);
          setDebtorsList(response.data);
          setLoadingDebtorsList(false);
        });
    } catch (error) {
      setLoadingDebtorsList(false);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }else{
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    
    getDebtorsList();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--20"
                alt=""
              />
            </span>
            <span>Debtors list</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card--header">
              <form>
                <div className="input-group">
                  <input
                    id="datatableSearch_"
                    type="search"
                    name="search"
                    className="form-control"
                    placeholder="Search by Name "
                    aria-label="Search"
                    defaultValue=""
                    required=""
                    onChange={(e) => filterDebtorsList(e.target.value)}
                  />
                </div>
              </form>
              {/* <div className="hs-unfold ml-auto">
                <a
                  className="js-hs-unfold-invoker btn btn-sm btn-outline-primary-2 dropdown-toggle min-height-40"
                  href="javascript:;"
                  data-hs-unfold-options='{
                              "target": "#usersExportDropdown",
                              "type": "css-animation"
                          }'
                >
                  <i className="tio-download-to mr-1" /> Export
                </a>
                <div
                  id="usersExportDropdown"
                  className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-sm-right"
                >
                  <span className="dropdown-header">Download Options</span>
                  <a
                    id="export-excel"
                    className="dropdown-item"
                    href="https://grofresh-admin.6amtech.com/admin/customer/export"
                  >
                    <img
                      className="avatar avatar-xss avatar-4by3 mr-2"
                      src="https://grofresh-admin.6amtech.com/public/assets/admin/svg/components/excel.svg"
                      alt="no data"
                    />
                    Excel
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            {loadingDebtorsList ? (
              <div style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"2rem"}} >

                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <table className="table table-borderless table-hover table-align-middle m-0 text-14px">
                <thead className="thead-light">
                  <tr className="word-nobreak">
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Customer name</th>
                    <th>Contact info</th>
                    <th>Sale ID</th>
                    <th className="text-center">Total Cost</th>
                    <th className="text-center">Amount Paid</th>
                    <th className="text-center">Outstanding Balance</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((sale, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="table-column-pl-0">
                        <a href="" className="product-list-media">
                          <div className="table--media-body">
                            <h5 className="title m-0">
                              {sale?.customer?.customer_name}
                            </h5>
                          </div>
                        </a>
                      </td>
                      <td>
                        <h5 className="m-0">
                          <a href="/cdn-cgi/l/email-protection#b7ddd2dadacef7d0dad6dedb99d4d8da">
                            <span
                              className="__cf_email__"
                              data-cfemail="4f252a2222360f28222e2623612c2022"
                            >
                              {sale?.customer?.customer_email}
                            </span>
                          </a>
                        </h5>
                        <div>
                          <a href="Tel:+8801732323232">
                            {sale?.customer?.customer_phone_number}
                          </a>
                        </div>
                      </td>
                      <td>{sale?.sale_id}</td>
                      <td>
                        <div className="text-center">
                          <span>{sale.total_cost}</span>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">{sale?.amount_paid}</div>
                      </td>
                      <td>
                        <div className="text-center">
                          {sale?.outstanding_balance}
                        </div>
                      </td>
                      {/* <td>
                      <div className="text-center">
                        {customer.total_outstanding_balance}
                      </div>
                    </td> */}
                      <td>
                        <div className="btn--container justify-content-center">
                          <Link
                            className="action-btn"
                            to={`/sales/${sale?.id}/details`}
                          >
                            <MdOutlineRemoveRedEye />
                          </Link>
                          
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {debtorsList.length <= 0 && !loadingDebtorsList && (
              <NoData />
            )}
          </div>
          <div className="card-footer">
            <nav>
              <ReactPaginate
                breakLabel="..."
                nextLabel="›"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="‹"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="page-item active"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
              />
            </nav>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CustomerDebts;
