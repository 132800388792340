import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../../context/AuthContext";
import { api_url } from "../../../utils/data";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";

const NewCoupon = () => {
  const { authToken } = useAuthenticate();
  const [couponData, setCouponData] = useState({});
  const [creatingCoupon, setCreatingCoupon] = useState(false);

  const handleFormChange = (e) => {
    setCouponData({
      ...couponData,
      [e.target.name]: e.target.value,
    });
  };

  const createCoupon = async (e) => {
    e.preventDefault();
    setCreatingCoupon(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };
    const body = JSON.stringify(couponData);
    await axios
      .post(`${api_url}/subscription/coupons/`, body, config)
      .then((response) => {
        setCreatingCoupon(false)
        if (response?.data?.success) {
          Swal.fire({
            title: "Created!",
            text: "Coupon created successfully",
            icon: "success",
          }).then(() => {
            window.location.href = "/super-admin/coupons/list";
          });
        } else {
          if (response?.data?.error) {
            for (const property in response.data?.error) {
              if (property !== "0") {
                toast.error(
                  `${property} error: ${response.data?.error[property]}`
                );
              } else {
                toast.error(`${response.data?.error[property]}`);
              }
            }
          }
        }
      }).catch((error)=>{
        setCreatingCoupon(false);
        console.log(error)
        toast.error("Something went wrong. Please try again later.")
      });
  };
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span> Create Coupon </span>
          </h1>
        </div>
        <form
          onSubmit={(e) => createCoupon(e)}
          id="product_form"
          className="row g-2"
        >
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body pt-2">
                <ul className="nav nav-tabs mb-4">
                  <li className="nav-item">
                    <a className="nav-link lang_link active" id="en-link">
                      Coupon Details
                    </a>
                  </li>
                </ul>
                <div className="lang_form" id="en-form">
                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Coupon Code *
                    </label>
                    <input
                      type="text"
                      name="code"
                      id="en_name"
                      className="form-control"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Discount Percentage
                    </label>
                    <input
                      type="number"
                      name="discount_percent"
                      id="en_pharmacy_location"
                      className="form-control"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Expiry Date
                    </label>
                    <input
                      type="date"
                      name="expiry_date"
                      id="en_pharmacy_phone"
                      className="form-control"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="btn--container justify-content-end">
              {!creatingCoupon ? (
                <>
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                </>
              ) : (
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  strokeColor="blue"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default NewCoupon;
