import React from "react";
import Logo from "../../assets/rxultimate.png";
import { DNA } from "react-loader-spinner";

const Loading = () => {
  return (
    <div
      style={{ minHeight:"100dvh" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <img src={Logo} alt="" style={{height:"100px"}} />
      <div>
        <DNA
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
    </div>
  );
};

export default Loading;
