import React, { useState } from "react";
import SidemenuItem from "./SidemenuItem";
import { IoCloseCircleOutline } from "react-icons/io5";

import PharmacyLogo from "../assets/rxultimate.png";

import {
  superadminMenuItems,
  menuItems,
  employeeMenuItems,
  alphaMenuItems,
  alphaEmployeeMenuItems,
  compactEmployeeMenuItems,
  compactMenuItems,
} from "../utils/userRoutes";
import { useAuthenticate } from "../context/AuthContext";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const { user, subscriptionDetails } = useAuthenticate();

  return (
    <aside
      style={{ marginLeft: openSidebar ? "0" : "-16.25rem" }}
      className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered"
    >
      <div className="navbar-vertical-container text-capitalize">
        <div className="navbar-vertical-footer-offset">
          <div className="navbar-brand-wrapper justify-content-between">
            <div className="navbar-brand font-bold" aria-label="Front">
              <img className="side-logo" src={PharmacyLogo} alt="Logo" />

              <span className="sidebar-close-icon">
                <IoCloseCircleOutline
                  className="ml-5"
                  onClick={() => setOpenSidebar(!openSidebar)}
                />
              </span>
            </div>
          </div>
          <div className="navbar-vertical-content" id="navbar-vertical-content">
            {/* NAVLIST */}
            {user?.is_superuser ? (
              <ul className="navbar-nav navbar-nav-lg nav-tabs mt-3">
                {user?.is_superuser && (
                  <>
                    {superadminMenuItems.map((menuItem, index) => (
                      <SidemenuItem
                        menuItem={menuItem}
                        key={index}
                        submenus={menuItem?.submenus}
                      />
                    ))}
                  </>
                )}

              </ul>
            ) : (
              <>
                {subscriptionDetails?.success && (
                  <>
                    {subscriptionDetails?.subscription?.package !==
                      "Alpha" && subscriptionDetails?.subscription?.package !== "Compact" && (
                      <ul className="navbar-nav navbar-nav-lg nav-tabs mt-3">
                        {user?.is_superuser && (
                          <>
                            {superadminMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}

                        {user?.is_pharmacy_admin && (
                          <>
                            {menuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}

                        {user?.is_employee && (
                          <>
                            {employeeMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}
                      </ul>
                    )}

                    {subscriptionDetails?.subscription?.package === "Alpha" && (
                      <ul className="navbar-nav navbar-nav-lg nav-tabs mt-3">
                        {user?.is_pharmacy_admin && (
                          <>
                            {alphaMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}

                        {user?.is_employee && (
                          <>
                            {alphaEmployeeMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}
                      </ul>
                    )}

                    {subscriptionDetails?.subscription?.package ===
                      "Compact" && (
                      <ul className="navbar-nav navbar-nav-lg nav-tabs mt-3">
                        {user?.is_pharmacy_admin && (
                          <>
                            {compactMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}

                        {user?.is_employee && (
                          <>
                            {compactEmployeeMenuItems.map((menuItem, index) => (
                              <SidemenuItem
                                menuItem={menuItem}
                                key={index}
                                submenus={menuItem?.submenus}
                              />
                            ))}
                          </>
                        )}
                      </ul>
                    )}
                  </>
                )}
              </>
            )}

            {/* END NAVLIST */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
