import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthenticate } from '../context/AuthContext'


const ProtectedRoutes = ({component: Component, ...rest}) => {
    
    const {user} = useAuthenticate();
    
    if (!user || user === "undefined" || user === undefined){
        
        return <Navigate to="/" />
    }else{
        
        return (
          <Component {...rest} />
        )
    }
}

export default ProtectedRoutes