import React, { useState } from "react";
import { useAuthenticate } from "../../../context/AuthContext";
import axios from "axios";
import { api_url } from "../../../utils/data";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const NewPharmacy = () => {
  const {packages,authToken} = useAuthenticate()
  const [pharmacyData, setPharmacyData] = useState(null)
  const [creatingPharmacy, setCreatingPharmacy] = useState(false)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [selectedPackage, setSelectedPackage] = useState({})
  const [subscriptionData, setSubscriptionData] = useState({})

  const handleFormChange = (e) => {
    setPharmacyData({
      ...pharmacyData, [e.target.name]: e.target.value
    })
  }

  const handleSubscriptionFormChange = (e) => {
    setSubscriptionData({
      ...subscriptionData, [e.target.name]: e.target.value
    })
  }

  const createPharmacy = async (e)=> {
    e.preventDefault();
    setCreatingPharmacy(true)
    const formData = new FormData();

    Object.keys(pharmacyData).forEach(key => {
      formData.append(key, pharmacyData[key]);
    })

    const config = {
      headers:{
        "Content-Type": "multipart/form-data",
        Authorization:"Bearer " + authToken
      }
    }

    await axios.post(`${api_url}/accounts/pharmacy/`,formData, config).then((response)=>{
      Swal.fire({
        title:"Created!",
        text:"Pharmacy created successfully!",
        icon:"success"
      }).then(()=>{
        window.location.href = "/super-admin/pharmacies/list"
      })
    }).catch((error)=>{
      console.log(error);
      setCreatingPharmacy(false);
      if(error?.response?.status === 500){
        return toast.error("Internal server error");
      }
      if(error?.response?.status === 400){
        for(const property in error.response.data){
          if(property !== "0"){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }else{
            toast.error(`${error.response.data[property]}`)
          }
        }
      }
    });

  }

  const subscribePharmacy = async (pharmacy_id) => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        Authorization:"Bearer " + authToken
      }
    }

    subscriptionData['pharmacy'] = pharmacy_id;

    const body = JSON.stringify(subscriptionData);

    await axios.post(`${api_url}/subscription/`, body, config).then((response)=>{
      console.log(response);
      Swal.fire({
        title:"Created!",
        text:"Pharmacy created and subscribed successfully!",
        icon:"success"
      }).then(()=>{
        window.location.href = "/super-admin/pharmacies/list"
      })
      setCreatingPharmacy(false)
    }).catch((error)=>{
      console.log(error);
      Swal.fire({
        title:"Created!",
        text:"Pharmacy created successfully, but subscription failed, please try subscribing later.",
        icon:"success"
      })
      setCreatingPharmacy(false);
    })
  }

  const getSelectedPackage = (package_id) => {
    
    const filteredPackage = packages.filter((packageItem) => {
      return parseInt(package_id) === parseInt(packageItem.id)
    })
    setSelectedPackage(filteredPackage[0]);
  }

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span> Register Pharmacy </span>
          </h1>
        </div>
        <form onSubmit={e=>createPharmacy(e)} id="product_form" className="row g-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body pt-2">
                <ul className="nav nav-tabs mb-4">
                  <li className="nav-item">
                    <a className="nav-link lang_link active" id="en-link">
                      Pharmacy Details
                    </a>
                  </li>
                </ul>
                <div className="lang_form" id="en-form">
                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Pharmacy Name *
                    </label>
                    <input
                      type="text"
                      name="pharmacy_name"
                      id="en_name"
                      className="form-control"
                      required
                      onChange={(e)=>handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Pharmacy Location
                    </label>
                    <input
                      type="text"
                      name="pharmacy_location"
                      id="en_pharmacy_location"
                      className="form-control"
                      onChange={(e)=>handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Pharmacy Phone Number
                    </label>
                    <input
                      type="text"
                      name="pharmacy_phone"
                      id="en_pharmacy_phone"
                      className="form-control"
                      onChange={(e)=>handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Pharmacy Email Address
                    </label>
                    <input
                      type="text"
                      name="pharmacy_email"
                      id="en_pharmacy_email"
                      className="form-control"
                      onChange={(e)=>handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Pharmacy Logo
                    </label>
                    <input
                      type="file"
                      name="pharmacy_logo"
                      id="en_pharmacy_logo"
                      onChange={(e)=>{
                        setPharmacyData({
                          ...pharmacyData,[e.target.name]:e.target.files[0]
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-user" />
                  </span>
                  <span> Subscription Package </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Package<span className="input-label-secondary">*</span>
                      </label>
                      <select
                        name="package"
                        onChange={(e)=>{handleSubscriptionFormChange(e);getSelectedPackage(e.target.value)}}
                        className="form-control js-select2-custom"
                        required
                      >
                        <option value="">---Select---</option>
                        {
                          packages?.map((package_item, index)=>(
                            <option key={index} value={package_item?.id}>
                              {package_item?.name} 
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlSelect1"
                            >
                              Package Cost
                            </label>
                            <input
                              type="text"
                              name="package_cost"
                              id="en_package_cost"
                              className="form-control"
                              value={selectedPackage?.price}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              className="input-label"
                              htmlFor="exampleFormControlSelect1"
                            >
                              Duration (days)
                            </label>
                            <input
                              type="number"
                              name="duration"
                              id="en_duration"
                              className="form-control"
                              value={selectedPackage?.duration}
                              disabled
                            />
                          </div>
                        </div>
                    </div>
                    
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label
                            className="input-label"
                            htmlFor="exampleFormControlSelect1"
                          >
                            Discount Coupon
                          </label>
                          <input
                            type="text"
                            name="coupon"
                            id="en_coupon"
                            className="form-control"
                            onChange={(e)=>handleSubscriptionFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="input-label" style={{color:"transparent"}}>a</label>
                          <button className="btn btn-primary" >Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Amount to pay
                      </label>
                      <input
                        type="text"
                        name="package_cost"
                        id="en_package_cost"
                        className="form-control"
                        value={selectedPackage?.price}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Amount Paid
                      </label>
                      <input
                        type="text"
                        name="amount_paid"
                        id="en_amount_paid"
                        className="form-control"
                        onChange={(e)=>handleSubscriptionFormChange(e)}
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Pharmacy Administrator Information </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          First Name *
                        </label>
                        <input
                          type="text"
                          onChange={(e)=>handleFormChange(e)}
                          name="first_name"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Last Name *
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          onChange={(e)=>handleFormChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          onChange={(e)=>handleFormChange(e)}
                          name="email"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Phone Number*
                        </label>
                        <input
                          type="text"
                          name="phone_number"
                          className="form-control"
                          onChange={(e)=>handleFormChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Username *
                        </label>
                        <input
                          type="text"
                          onChange={(e)=>handleFormChange(e)}
                          name="username"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Password *
                        </label>
                        <input
                          type="text"
                          name="password"
                          className="form-control"
                          onChange={(e)=>handleFormChange(e)}
                          required
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="btn--container justify-content-end">
              {
                !creatingPharmacy ? (
                  <>
                    <a href="" className="btn btn--reset min-w-120px">
                      Reset
                    </a>
                    <button type="submit" className="btn btn--primary">
                      Submit
                    </button>
                  </>
                ):(
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    strokeColor="blue"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )
              }
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default NewPharmacy;
